import { Component, Output, EventEmitter } from '@angular/core';
import { RadioType } from '../../types';

@Component({
  selector: 'app-create-store',
  templateUrl: './create-store.component.html',
  styleUrls: ['./create-store.component.scss'],
})
export class CreateStoreComponent {
  @Output() formValChange = new EventEmitter();
  form = {
    radioVal: '',
    url: '',
    category: '',
    design: '',
  };
  categories: any[] = [];

  get isUrlInputShow() {
    return this.form.radioVal === RadioType.UseExistingWebsite;
  }
  get isSelectShow() {
    return this.form.radioVal === RadioType.UseDefaultLayout;
  }
  get isDesignInputShow() {
    return this.form.radioVal === RadioType.UseDesignFile;
  }

  onRadioChange(event) {
    console.log(event);
    this.form.url = '';
    this.form.design = '';
    this.form.design = '';
    this.onChange();
  }
  onUrlChange(event) {
    const { value = '' } = event.target;
    this.form.url = value;
    this.onChange();
  }
  onDesignChange(event) {
    const { value = '' } = event.target;
    this.form.design = value;
    this.onChange();
  }
  onChange() {
    const { form } = this;
    this.formValChange.emit(form);
  }
}
