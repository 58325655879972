<fd-dynamic-page size="large" ariaLabel="Example Dynamic Page" [autoResponsive]="false">
  <fd-dynamic-page-header title="Store Generator"> </fd-dynamic-page-header>
  <fd-dynamic-page-subheader [collapsible]="false" [pinnable]="false" [collapsed]="false">
    <app-store-select [stores]="stores" [(value)]="currentStore" (valueChange)="noop($event)">
      <div class="flex">
        <fd-switch [(ngModel)]="checked1" (ngModelChange)="onSwitchChange1($event)"></fd-switch>
        <fd-switch [(ngModel)]="checked2" (ngModelChange)="onSwitchChange2($event)"></fd-switch>
      </div>
    </app-store-select>
  </fd-dynamic-page-subheader>

  <fd-tab-list [stackContent]="false" maxContentHeight="auto" ariaRoledescription="Tab Bar">
    <fd-tab [title]="tabs[1].text">
      <fd-dynamic-page-content [id]="tabs[1].id" cdkScrollable class="h-full !p-6">
        <ng-template [ngIf]="needOptimization" [ngIfElse]="empty2">
          <app-campaign-optimization></app-campaign-optimization>
        </ng-template>
        <ng-template #empty2>
          <app-empty-page></app-empty-page>
        </ng-template>
      </fd-dynamic-page-content>
    </fd-tab>
    <fd-tab [title]="tabs[0].text">
      <fd-dynamic-page-content [id]="tabs[0].id" cdkScrollable class="h-full !p-6">
        <ng-template [ngIf]="needOptimization" [ngIfElse]="empty1">
          <div class="fd-dynamic-page-section-example">content 123.</div>
        </ng-template>
        <ng-template #empty1>
          <app-empty-page></app-empty-page>
        </ng-template>
      </fd-dynamic-page-content>
    </fd-tab>
    <fd-tab [title]="tabs[2].text">
      <fd-dynamic-page-content [id]="tabs[2].id" cdkScrollable class="h-full !p-6">
        <ng-template [ngIf]="needOptimization" [ngIfElse]="empty3">
          <div class="fd-dynamic-page-section-example">content 789.</div>
        </ng-template>
        <ng-template #empty3>
          <app-empty-page></app-empty-page>
        </ng-template>
      </fd-dynamic-page-content>
    </fd-tab>
  </fd-tab-list>

  <!-- <fd-dynamic-page-footer [hidden]="!isFooterShow">
    <div fd-bar barDesign="floating-footer">
      <div fd-bar-right>
        <fd-button-bar label="Back" (click)="test()"></fd-button-bar>
        <fd-button-bar label="Create" fdType="emphasized" (click)="test()"></fd-button-bar>
      </div>
    </div>
  </fd-dynamic-page-footer> -->
</fd-dynamic-page>
