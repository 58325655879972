import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class ContainerPageService {
  constructor(private http: HttpClient) {}

  // getStores(): Observable<any[]> {
  //   return this.http.get<any[]>('/api/flow-riser-backend/getStores').pipe(
  //     map((data) => {
  //       console.log('Data received:', data);
  //       return data;
  //     }),
  //     catchError(this.handleError),
  //   );
  // }

  private handleError(res: HttpErrorResponse | any) {
    console.error(res.error || res.body.error);
    return throwError(() => new Error(res.error || 'Server error'));
  }

  getStores(): Observable<boolean> {
    return of(true).pipe(
      switchMap(() => timer(2000)),
      switchMap(() => of(true)),
    );
  }
}
