import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/core/navigation.service';

@Injectable()
export class CreateCampaignPageService {
  constructor(
    private router: Router,
    private navigation: NavigationService,
  ) {}

  validate(val) {
    if (!val || !val.trim()) {
      return false;
    }
    return true;
  }

  validateAll(form, formState) {
    let result = true;
    for (const [key, val] of Object.entries(form)) {
      if (this.validate(val)) {
        formState[key] = 'default';
      } else {
        result = false;
        formState[key] = 'error';
      }
    }
    return result;
  }

  updateState(key, val, formState) {
    if (this.validate(val) && formState[key] === 'error') {
      formState[key] = 'default';
      return;
    }
    if (!this.validate(val) && formState[key] !== 'error') {
      formState[key] = 'error';
      return;
    }
  }

  goBack(event) {
    event.preventDefault();
    this.navigation.back();
  }

  goPreviewPage() {
    this.router.navigate(['/store-generator/preview']);
  }
}
