import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusyIndicatorModule } from '@fundamental-ngx/core/busy-indicator';

import { GlobalLoadingComponent } from './global-loading/global-loading.component';
import { GlobalLoadingService } from './global-loading/global-loading.service';

@NgModule({
  declarations: [GlobalLoadingComponent],
  imports: [CommonModule, BusyIndicatorModule],
  exports: [GlobalLoadingComponent],
  providers: [GlobalLoadingService],
})
export class WidgetsModule {}
