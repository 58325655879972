import { Injectable } from '@angular/core';
import { HotZone, TargetNode } from 'src/app/store-generator/types';

const DELAY = 500;
const MAX_COUNT = 20;
const BOARDER_WIDTH = 2;
const BORDER_COLOR = '#0070F2';
const TRANSPARENT_COLOR = 'transparent';
const IFRAME_MIN_HEIGHT = 1500;

const calBorderPosition = (val) => {
  return `${val}px`;
  // return Number(val) < BOARDER_WIDTH ? '0px' : `${val}px`;
};

@Injectable()
export class PreviewScreenService {
  doc!: Document;
  targetNodes: TargetNode[] = [];
  elms: HTMLElement[] = [];
  hotZones: HotZone[] = [];
  constructor() {
    this.gen = this.gen.bind(this);
  }

  showHotZone(hotZone: HotZone) {
    if (!hotZone.isActive) {
      hotZone.style['border-color'] = BORDER_COLOR;
      hotZone.isActive = true;
    }
  }

  hideHotZone(hotZone: HotZone) {
    if (hotZone.isActive) {
      hotZone.style['border-color'] = TRANSPARENT_COLOR;
      hotZone.isActive = false;
    }
  }

  genHotZoneSourceData(elm: Element, id: string, label: string, style = {}, customId = '') {
    const rect = elm.getBoundingClientRect();
    const { width, height, top, right, left, bottom } = rect;
    return {
      id,
      label,
      isActive: false,
      area: {
        x1: left,
        y1: top,
        x2: left + width,
        y2: top + height,
      },
      style: {
        width: `${width}px`,
        height: `${height}px`,
        top: calBorderPosition(top),
        right: calBorderPosition(right),
        left: calBorderPosition(left),
        bottom: calBorderPosition(bottom),
        border: `${BOARDER_WIDTH}px dashed ${TRANSPARENT_COLOR}`,
        'border-color': TRANSPARENT_COLOR,
        ...style,
      },
      customId,
    };
  }

  findNode1(parentNode: Document | HTMLElement, selector: string) {
    const elms = parentNode.querySelectorAll(selector);
    return elms;
  }

  findNode2(parentNode: Document | HTMLElement, selectors: (string | number)[]) {
    return selectors.reduce((parent, selector) => {
      if (!parent) {
        return;
      }
      if (typeof selector === 'string') {
        const elms = parent.querySelectorAll(selector);
        return elms;
      }
      if (typeof selector === 'number') {
        if (!Reflect.has(parent, selector)) {
          return;
        }
        const elms = Reflect.get(parent, selector);
        return elms;
      }
      return parent;
    }, parentNode);
  }

  findAllNodes(parentNode: Document | HTMLElement, targetNodes: TargetNode[]) {
    const elmList: Element[] = [];
    const hotZoneList: HotZone[] = [];
    for (const each of targetNodes) {
      const { selector, index, id, label, style, genCustomId } = each;
      let elm;
      if (Array.isArray(selector)) {
        elm = this.findNode2(parentNode, selector);
      } else {
        const elms = Array.from(this.findNode1(parentNode, selector));
        if (elms?.length < index + 1) {
          console.warn(`elms: ${elms} find by ${selector} is less than ${index + 1}`);
          return { elmList, hotZoneList };
        }
        elm = elms[index];
      }
      if (elm) {
        let customId = '';
        if (genCustomId) {
          customId = genCustomId(elm);
        }
        elmList.push(elm);
        const hotZone = this.genHotZoneSourceData(elm, id, label, style, customId);
        hotZoneList.push(hotZone);
      }
    }
    // console.log('*** findAllNode ***', elmList);
    return { elmList, hotZoneList };
  }

  *gen() {
    let elms;
    while (!elms?.length) {
      elms = yield this.findAllNodes(this.doc, this.targetNodes);
    }
    return { elmList: this.elms, hotZoneList: this.hotZones };
  }

  run(gen, before, after, callback) {
    const i = gen();
    const next = () => {
      if (before && !before(next)) {
        return;
      }
      const result = i.next(this.elms);
      if (result.done) {
        console.log('*** Done ***', result);
        return;
      }
      callback && callback(result.value);
      after && after(next);
    };
    return next();
  }

  runUpdateHotZone(doc: Document, targetNodes: TargetNode[]) {
    this.doc = doc;
    this.targetNodes = targetNodes;

    let timeout;
    let count = 0;
    const before = (next: () => HTMLElement[] | undefined | void) => {
      if (count > MAX_COUNT) {
        return false;
      }
      count++;
      return true;
    };
    const after = (next: () => HTMLElement[] | undefined | void) => {
      timeout = setTimeout(() => {
        timeout = null;
        next();
      }, DELAY);
    };
    const callback = (result: { elmList: HTMLElement[]; hotZoneList: HotZone[] }) => {
      const { elmList, hotZoneList } = result;
      this.elms = elmList;
      this.hotZones = hotZoneList;
    };
    this.run(this.gen, before, after, callback);
    return this.hotZones;
  }

  async intervalExecutor(task, condition, callback, interval = 1000, ...args) {
    const intervalGenerator = function* (task, condition, interval) {
      while (true) {
        if (condition && condition()) {
          break;
        }
        const data = task(...args);
        yield new Promise((resolve) => setTimeout(() => resolve(data), interval));
      }
    };
    const executor = intervalGenerator(task, condition, interval);
    for await (const data of executor) {
      callback && callback(data);
    }
  }

  runIntervalTask(task, interval?: number, max = 6) {
    let count = 0;
    const condition = () => {
      return count >= max;
    };
    const callback = () => {
      count++;
    };
    this.intervalExecutor(task, condition, callback, interval);
  }

  runIntervalTaskOnce(task, interval = 1000, max = 6) {
    let count = 0;
    let done = false;
    const condition = () => {
      if (count >= max) {
        return true;
      }
      return done === true;
    };
    const callback = (result) => {
      done = result;
      count++;
    };
    this.intervalExecutor(task, condition, callback, interval);
  }

  hackIframeStyle(ifDoc: Document) {
    const container = ifDoc.querySelector('body');
    if (container) {
      container.style.overflow = 'hidden';
    }
  }

  getIframeSize(ifDoc: Document) {
    let height = 0;
    const container = ifDoc.querySelector('body');
    if (container) {
      height =
        container.scrollHeight > IFRAME_MIN_HEIGHT ? container.scrollHeight : IFRAME_MIN_HEIGHT;
      console.log('*** container.scrollHeight ***', container.scrollHeight);
    }
    return height;
  }

  updateIframeSize(IframeRef, height) {
    IframeRef.nativeElement.style.height = `${height}px`;
    console.log('*** updateIframeSize ***', height);
  }

  runUpdateIframeSize(ifDoc: Document, IframeRef) {
    return new Promise((resolve, reject) => {
      const max = 10;
      let count = 0;
      let height = 0;
      const interval = 1000;
      const condition = () => {
        if (count >= max) {
          console.log(
            '***** UpdateIframeSize Finish *****',
            `run ${count} times`,
            `Exceeded the maximum number of times ${max}`,
          );
          this.updateIframeSize(IframeRef, this.getIframeSize(ifDoc));
          reject(false);
          return true;
        }
        if (height > IFRAME_MIN_HEIGHT) {
          console.log('***** UpdateIframeSize Finish *****', `run ${count} times`);
          resolve(true);
          return true;
        }
        return false;
      };
      const callback = (val) => {
        count++;
        height = val;
        if (val > IFRAME_MIN_HEIGHT) {
          this.updateIframeSize(IframeRef, val);
        }
      };
      this.intervalExecutor(this.getIframeSize, condition, callback, interval, ifDoc);
    });
  }

  // runUpdateIframeSize(ifDoc: Document, IframeRef) {
  //   const run = (delay, resolve) => {
  //     if (delay <= 0) {
  //       resolve();
  //       return;
  //     }
  //     const height = this.updateIframeSize(ifDoc, IframeRef);
  //     if (height > IFRAME_MIN_HEIGHT) {
  //       resolve(height);
  //     }
  //     setTimeout(() => {
  //       run(delay - 1000, resolve);
  //     }, delay);
  //   };
  //   return new Promise((resolve) => {
  //     run(2000, resolve);
  //   });
  // }
}
