<div class="preview-popup">
  <fd-icon
    ng-reflect-glyph="notification"
    class="sap-icon--notification preview-popup-close"
    (click)="onClose()"
  ></fd-icon>
  <div class="flex justify-between">
    <b class="text-base">{{ title }}</b>
    <button fd-button fdType="transparent">{{ buttonText }}</button>
  </div>
  <div class="flex-1 flex flex-col justify-between mt-2.5">
    <div class="preview-popup-row" *ngFor="let tag of tags">
      <button
        fd-button
        class="fd-button-override preview-popup-tag"
        [id]="tag.id"
        (click)="onSelect(tag)"
      >
        {{ tag.text }}
      </button>
    </div>
  </div>
  <div class="preview-popup-overlay flex-center" *ngIf="!loaded">
    <fd-busy-indicator class="preview-popup-loading" [loading]="true" size="m"></fd-busy-indicator>
  </div>
</div>
