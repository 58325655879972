import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublishedCompaignsComponent } from './published-compaigns/published-compaigns.component';
import { SocialPageComponent } from './social-page/social-page.component';
const routes: Routes = [
  { path: 'total-commerce', component: PublishedCompaignsComponent },
  { path: 'create-new-campaign', component: SocialPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TotalCommerceRoutingModule {}
