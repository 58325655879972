<h4 fd-form-header>1. General</h4>
<p class="m-2">Create shoppable pages for your social media profiles.</p>
<br />
<div class="flex items-center justify-center flex-col w-full">
  <form #myForm="ngForm">
    <fdp-form-group [formGroup]="formTypesGroupRegister">
      <fdp-form-field required="true" #ffl1 label="Channel:" id="input1" [rank]="1">
        <fd-select
          name="input1"
          placeholder=""
          [(value)]="selectedChannel"
          (valueChange)="onSelect()"
        >
          <li fd-option *ngFor="let option of channels" [value]="option">{{ option }}</li>
        </fd-select>
      </fdp-form-field>

      <fdp-form-field required="true" #ffl1 label="Marketing Purpose:" id="input2" [rank]="1">
        <fd-select name="input2" [(value)]="selectedPurpose" (valueChange)="onSelect()">
          <li fd-option *ngFor="let option of purposes" [value]="option">{{ option }}</li>
        </fd-select>
      </fdp-form-field>

      <fdp-form-field required="true" #ffl1 label="Campaign Name:" id="input3" [rank]="1">
        <fdp-input name="input3" type="text" (input)="onChange($event)"> </fdp-input>
      </fdp-form-field>

      <fdp-form-field required="false" #ffl1 label="Subtitle:" id="input4" [rank]="1">
        <fdp-textarea name="input4" [growing]="true"> </fdp-textarea>
      </fdp-form-field>
    </fdp-form-group>
  </form>
</div>
