import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getIdFromSrc } from 'src/app/common/utils';
import { DataCenterService } from 'src/app/core/data-center.service';

@Injectable({
  providedIn: 'root',
})
export class PreviewControlService {
  // TODO:
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  count = 0;

  originBannerId = '';
  originProductIds = {};

  constructor(private dataCenterService: DataCenterService) {}

  show(): void {
    this.count++;
    this.isLoadingSubject.next(true);
  }

  hide(): void {
    if (this.count > 0) {
      this.count--;
    }
    if (this.count <= 0) {
      this.isLoadingSubject.next(false);
    }
  }

  getBannerId() {
    if (this.originBannerId) {
      return this.originBannerId;
    }
    let id;
    const iframe = document.getElementById('preview-frame') as HTMLIFrameElement;
    if (iframe) {
      const document = iframe.contentDocument as Document;
      const banner = this.dataCenterService.getBanner(document);
      if (banner) {
        const img = banner.querySelector('img');
        if (img) {
          id = getIdFromSrc(img.src);
        }
      }
    }
    if (!id) {
      console.error('Cannot find banner img id');
      return;
    }
    this.originBannerId = id;
    return id;
  }

  getOriginalProdId(index: number) {
    let id;
    const iframe = document.getElementById('preview-frame') as HTMLIFrameElement;
    if (iframe) {
      const document = iframe.contentDocument as Document;
      const items = this.dataCenterService.getProductSwiperItems(document);
      if (items.length) {
        const product = items[index];
        const img = product.querySelector('img');
        if (img) {
          id = getIdFromSrc(img.src);
        }
      }
      if (!id) {
        console.error(`Cannot find product img id at position ${index}`);
        return;
      }
      if (Reflect.has(this.originProductIds, id)) {
        return Reflect.get(this.originProductIds, id);
      }
      Reflect.set(this.originProductIds, id, id);
    }
    return id;
  }

  updateOriginalProdId(id, newId) {
    const originalId = Reflect.get(this.originProductIds, id);
    Reflect.set(this.originProductIds, newId, originalId);
  }
}
