import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import {
  // FD_FLEXIBLE_LAYOUT_CONFIG,
  FlexibleColumnLayout,
  // FlexibleLayoutConfig,
} from '@fundamental-ngx/core/flexible-column-layout';
import { ColumnsWidthConfig } from '@fundamental-ngx/core/fixed-card-layout';

@Component({
  selector: 'app-tc-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
})
export class Step3Component implements OnInit {
  localLayout: FlexibleColumnLayout = 'OneColumnStartFullScreen';
  miniHeaderRowSpan = 4;
  card3MiniHeaderRowSpan = 5;
  miniContentRowSpan = 5;
  products = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
  ];
  initialRows = 3;
  columnsWidthConfig: ColumnsWidthConfig = { '1': 50, '2': 100, '3': 165 };
  selectedProducts = [];
  ngOnInit(): void {
    this.changeLayout('TwoColumnsStartExpanded');
  }

  changeLayout(newValue: FlexibleColumnLayout): void {
    this.localLayout = newValue;
  }

  onSelectionChange(event): void {
    this.selectedProducts = event.selection;
  }
}
