import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundamentalNgxCoreModule } from '@fundamental-ngx/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { StoreGeneratorRoutingModule } from './store-generator-routing.module';
import { ContainerPageComponent } from './container-page/container-page.component';
import { ContainerPageService } from './container-page/container-page.service';

/** CreateStore **/
import { CreateStorePageComponent } from './create-store-page/create-store-page.component';
import { CreateStoreComponent } from './create-store-page/create-store/create-store.component';
import { CreateStorePageService } from './create-store-page/create-store-page.service';

/** PreviewStore **/
import { PreviewStorePageComponent } from './preview-store-page/preview-store-page.component';
import { PreviewStorePageService } from './preview-store-page/preview-store-page.service';
import { PreviewScreenComponent } from './preview-store-page/preview-screen/preview-screen.component';
import { PreviewControlComponent } from './preview-store-page/preview-control/preview-control.component';
import { PreviewControlService } from './preview-store-page/preview-control/preview-control.service';
import { PreviewScreenService } from './preview-store-page/preview-screen/preview-screen.service';
import { PreviewPopupComponent } from './preview-store-page/components/preview-popup/preview-popup.component';
import { ControlPanelNavigatorComponent } from './preview-store-page/components/control-panel-navigator/control-panel-navigator.component';
import { ControlPanelBannerComponent } from './preview-store-page/components/control-panel-banner/control-panel-banner.component';
import { ControlPanelRecommendationComponent } from './preview-store-page/components/control-panel-recommendation/control-panel-recommendation.component';
import { ControlPanelProductComponent } from './preview-store-page/components/control-panel-product/control-panel-product.component';
import { ControlPanelProductService } from './preview-store-page/components/control-panel-product/control-panel-product.service';

/** OptimizeStore **/
import { OptimizeStorePageComponent } from './optimize-store-page/optimize-store-page.component';
import { EmptyPageComponent } from './optimize-store-page/empty-page/empty-page.component';
import { CampaignOptimizationComponent } from './optimize-store-page/campaign-optimization/campaign-optimization.component';
import { BasicOptimizationComponent } from './optimize-store-page/basic-optimization/basic-optimization.component';
import { CreateCampaignPageComponent } from './optimize-store-page/create-campaign-page/create-campaign-page.component';
import { CreateCampaignPageService } from './optimize-store-page/create-campaign-page/create-campaign-page.service';

/** Commmon **/
import { WidgetsModule } from '../widgets/widgets.module';
import { SimpleCardComponent } from './components/simple-card/simple-card.component';
import { StoreSelectComponent } from './components/store-select/store-select.component';
import { ColorSelectComponent } from './components/color-select/color-select.component';
import { CheckPositionDirective } from '../core/check-position.directive';

@NgModule({
  imports: [
    CommonModule,
    WidgetsModule,
    StoreGeneratorRoutingModule,
    FundamentalNgxCoreModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ContainerPageComponent,
    CreateStorePageComponent,
    CreateStoreComponent,
    OptimizeStorePageComponent,
    EmptyPageComponent,
    SimpleCardComponent,
    StoreSelectComponent,
    ColorSelectComponent,
    PreviewControlComponent,
    PreviewScreenComponent,
    PreviewStorePageComponent,
    CampaignOptimizationComponent,
    BasicOptimizationComponent,
    CreateCampaignPageComponent,
    PreviewPopupComponent,
    ControlPanelNavigatorComponent,
    ControlPanelBannerComponent,
    ControlPanelRecommendationComponent,
    ControlPanelProductComponent,
    CheckPositionDirective,
  ],
  providers: [
    ContainerPageService,
    CreateStorePageService,
    PreviewStorePageService,
    CreateCampaignPageService,
    PreviewScreenService,
    PreviewControlService,
    ControlPanelProductService,
  ],
})
export class StoreGeneratorModule {}
