<fd-dynamic-page
  class="create-store-page"
  size="large"
  ariaLabel="Example Dynamic Page"
  [autoResponsive]="false"
>
  <fd-dynamic-page-header title="Store Generator"> </fd-dynamic-page-header>
  <fd-dynamic-page-subheader [collapsible]="false" [pinnable]="false" [collapsed]="false">
    <app-store-select
      [stores]="stores"
      [(value)]="currentStore"
      (valueChange)="noop($event)"
    ></app-store-select>
  </fd-dynamic-page-subheader>

  <fd-dynamic-page-content cdkScrollable class="!pb-18">
    <app-create-store (formValChange)="onFormChange($event)"></app-create-store>
  </fd-dynamic-page-content>

  <fd-dynamic-page-footer>
    <div fd-bar barDesign="floating-footer">
      <div fd-bar-right>
        <fd-button-bar
          label="Create"
          fdType="emphasized"
          [disabled]="isPreviewBtnDisabled"
          (click)="onCreate()"
        ></fd-button-bar>
      </div>
    </div>
  </fd-dynamic-page-footer>
</fd-dynamic-page>
