<div cdkScrollable #overlay>
  <ng-container>
    <!-- Flexible Column Layout -->
    <fd-flexible-column-layout [(layout)]="localLayout">
      <ng-template #startColumn>
        <div class="flex flex-col space-y-2">
          <fd-toolbar>
            <h3 fd-title>3. Campaign Products</h3>
            <fd-toolbar-spacer></fd-toolbar-spacer>
            <button fd-button fdType="transparent" label="Preview"></button>
          </fd-toolbar>

          <div class="searchDiv">
            <fdp-search-field
              [disableRefresh]="true"
              class="w-90"
              placeholder="Search by keyword, product ID"
            ></fdp-search-field>
            <fd-message-strip
              type="information"
              [dismissible]="false"
              [noIcon]="true"
              *ngIf="selectedProducts.length > 0"
            >
              Selected: {{ selectedProducts.length }}
            </fd-message-strip>

            <fd-grid-list selectionMode="multiSelect" (selectionChange)="onSelectionChange($event)">
              <fd-grid-list-item *ngFor="let product of products" [value]="product.id">
                <ng-template fd-grid-list-item-body>
                  <div class="flex flex-col space-y-2">
                    <img width="155" height="155" src="assets/images/cup.png" />
                    <div class="ml-2 w-20">
                      <h2>Mug. 300 ml</h2>
                      <h2>£1.50</h2>
                    </div>
                  </div>
                </ng-template>
              </fd-grid-list-item>
            </fd-grid-list>
          </div>
        </div>
      </ng-template>
      <ng-template #midColumn>
        <app-preview-card
          (changeLayout)="changeLayout('OneColumnStartFullScreen')"
        ></app-preview-card>
      </ng-template>
    </fd-flexible-column-layout>
  </ng-container>
</div>
