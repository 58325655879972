import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getCookieValue } from '../common/utils';

@Injectable({
  providedIn: 'root',
})
export class HttpAuthService {
  constructor() {}
  getAuthToken(): Observable<string> {
    return new Observable((observer) => {
      const token = getCookieValue('gin');
      observer.next(`gin=${token}`);
      observer.complete();
    });
  }
}
