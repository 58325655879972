<fd-toolbar>
  <h3 fd-title>Attachments ({{ files.length }})</h3>
  <fd-toolbar-spacer></fd-toolbar-spacer>
  <fd-file-uploader
    fdType="transparent"
    [inputHidden]="true"
    ariaLabel="Choose file"
    ariaLabelledBy="Choose file"
    buttonLabel="Upload"
    buttonAriaLabel="browse file"
    [required]="false"
    [(ngModel)]="files"
    [multiple]="true"
  >
  </fd-file-uploader>
</fd-toolbar>
<div class="illustrated-message">
  <figure fd-illustrated-message [svgConfig]="uploadCollectionConfig" *ngIf="files.length === 0">
    <figcaption fd-illustrated-message-figcaption>
      <h3 fd-illustrated-message-title>Drop files here</h3>
      <p fd-illustrated-message-text>
        You can upload several files at once. You can also use the upload button.
      </p>
    </figcaption>
  </figure>
  <fd-upload-collection *ngIf="files.length > 0" class="w-full">
    <li
      fd-list-item
      fd-upload-collection-item
      *ngFor="let file of files"
      [fileName]="file.fileName"
    >
      <span fd-list-thumbnail fd-upload-collection-thumbnail
        ><fd-icon glyph="picture"></fd-icon
      ></span>
      <div fd-list-content>
        <div fd-upload-collection-title-container>
          <a href="#" fd-link fd-list-title fd-upload-collection-title></a>
          <fd-upload-collection-form-item></fd-upload-collection-form-item>
        </div>
        <div fd-upload-collection-description>File Size: {{ file.size }} KB</div>
      </div>
      <fd-upload-collection-button-group
        [allowFileNameEdit]="false"
      ></fd-upload-collection-button-group>
    </li>
  </fd-upload-collection>
</div>
