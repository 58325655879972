import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PopupTagId } from '../types';

@Injectable()
export class PreviewStorePageService {
  constructor(private http: HttpClient) {}

  updateNavText(data): Observable<any> {
    return this.http.post<any>('/api/flow-riser-backend/edit/navigator/text', data).pipe(
      map((data) => {
        console.log('Data received:', data);
        return data;
      }),
      catchError(this.handleError),
    );
  }
  updateBannerImg(data): Observable<any> {
    return this.http.post<any>('/api/flow-riser-backend/edit/banner/pic', data).pipe(
      map((data) => {
        console.log('Data received:', data);
        return data;
      }),
      catchError(this.handleError),
    );
  }
  updateBannerText(data): Observable<any> {
    return this.http.post<any>('/api/flow-riser-backend/edit/banner/text', data).pipe(
      map((data) => {
        console.log('Data received:', data);
        return data;
      }),
      catchError(this.handleError),
    );
  }
  updateRecProduct(data): Observable<any> {
    return this.http.post<any>('/api/flow-riser-backend/edit/recommendation/products', data).pipe(
      map((data) => {
        console.log('Data received:', data);
        return data;
      }),
      catchError(this.handleError),
    );
  }
  updateProductImg(data): Observable<any> {
    return this.http.post<any>('/api/flow-riser-backend/edit/new/product', data).pipe(
      map((data) => {
        console.log('Data received:', data);
        return data;
      }),
      catchError(this.handleError),
    );
  }

  private handleError(res: HttpErrorResponse | any) {
    console.error(res.error || res.body.error);
    return throwError(() => new Error(res.error || 'Server error'));
  }

  apply({ tagId, data }: { tagId: string; data: any }): Observable<any> {
    if (tagId === PopupTagId.NavText) {
      return this.updateNavText(data);
    }
    if (tagId === PopupTagId.BannerImg) {
      return this.updateBannerImg(data);
    }
    if (tagId === PopupTagId.BannerText) {
      return this.updateBannerText(data);
    }
    if (tagId === PopupTagId.RecProduct) {
      return this.updateRecProduct(data);
    }
    if (tagId === PopupTagId.ProductImg) {
      const { origin_id, new_id } = data;
      return this.updateProductImg({ origin_id, new_id });
    }
    return of(true);
  }

  syncFigma(url: string): Observable<any[]> {
    const params = new HttpParams().set('url', url);
    return this.http.get<any>('/api/flow-riser-backend/figma/sync', { params }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError),
    );
  }
}
