<fd-dynamic-page size="large" ariaLabel="Example Dynamic Page" [autoResponsive]="false">
  <fd-dynamic-page-header title="Total Commerce"> </fd-dynamic-page-header>
  <fd-dynamic-page-subheader [collapsible]="false" [pinnable]="false" [collapsed]="false">
    <div class="flex items-center">
      <label fd-form-label for="fd-select-store">Storefront:</label>
      <fd-select
        class="w-[280px]"
        id="fd-select-store"
        placeholder="Select a store"
        value="fun-shop"
      >
        <li fd-option *ngFor="let option of stores" [value]="option.id">{{ option.name }}</li>
      </fd-select>
    </div>
  </fd-dynamic-page-subheader>

  <fd-dynamic-page-content cdkScrollable>
    <fdp-table [dataSource]="source" emptyTableMessage="No data found" rowNavigatable="navigatable">
      <fdp-table-toolbar
        title="Published Campaigns"
        [shouldOverflow]="true"
        [hideSearchInput]="true"
      >
        <fdp-table-toolbar-actions>
          <fdp-button buttonType="transparent" label="Create" (click)="onCreate()"></fdp-button>
          <fdp-button buttonType="transparent" glyph="action-settings" title="Setting"></fdp-button>
        </fdp-table-toolbar-actions>
      </fdp-table-toolbar>

      <fdp-column name="name" key="name" label="Campaign Name" align="start"> </fdp-column>

      <fdp-column name="channel" key="channel" label="Channel"> </fdp-column>

      <fdp-column name="purpose" key="purpose" label="Marketing Purpose"> </fdp-column>

      <fdp-column name="publishedDate" key="publishedDate" label="Published Date" align="start">
        <fdp-table-cell *fdpCellDef="let item">
          {{ item.publishedDate | date: 'MM/dd/yyyy' }}
        </fdp-table-cell>
      </fdp-column>
    </fdp-table>
  </fd-dynamic-page-content>

  <fd-dynamic-page-footer>
    <div fd-bar barDesign="floating-footer">
      <div fd-bar-right>
        <ng-template>
          <fd-button-bar label="Preview and Adjust" fdType="emphasized"></fd-button-bar>
        </ng-template>
        <ng-template>
          <fd-button-bar label="Back"></fd-button-bar>
          <fd-button-bar label="Create" fdType="emphasized"></fd-button-bar>
        </ng-template>
      </div>
    </div>
  </fd-dynamic-page-footer>
</fd-dynamic-page>
