<div class="mt-5">
  <label fd-form-label for="select-rec">Recommendation from:</label>
  <fd-select
    id="select-rec"
    class="w-full"
    placeholder="Select"
    [(ngModel)]="form.rec"
    (ngModelChange)="onSelectionChange($event)"
  >
    <ng-container *ngFor="let each of recommendations">
      <li fd-option [value]="each.value">{{ each.text }}</li>
    </ng-container>
  </fd-select>
</div>
