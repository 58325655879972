import { Component } from '@angular/core';
import {
  GridListItemOutputEvent,
  GridListItemType,
  GridListSelectionEvent,
} from '@fundamental-ngx/core/grid-list';

@Component({
  selector: 'app-fashion-icon',
  templateUrl: './fashion-icon.component.html',
  styleUrls: ['./fashion-icon.component.scss'],
})
export class FashionIconComponent {
  sceneConfig = {
    scene: {
      url: 'assets/images/sapIllus-Scene-SearchFolder.svg',
      id: 'sapIllus-Scene-SearchFolder',
    },
    dialog: {
      url: 'assets/images/sapIllus-Dialog-SearchFolder.svg',
      id: 'sapIllus-Dialog-SearchFolder',
    },
  };

  // templates = [];
  templates = [{}, {}, {}, {}, {}, {}, {}, {}];

  isTemplateSelected: boolean = false;

  onTemplateSelect(): void {
    this.isTemplateSelected = true;
  }

  onBackToTemplates(): void {
    this.isTemplateSelected = false;
  }
}
