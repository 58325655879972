import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TextSizeOptions, PopupTagId } from 'src/app/store-generator/types';

@Component({
  selector: 'app-control-panel-navigator',
  templateUrl: './control-panel-navigator.component.html',
  styleUrls: ['./control-panel-navigator.component.scss'],
})
export class ControlPanelNavigatorComponent {
  @Input() tagId = '';
  @Output() selectChange = new EventEmitter();

  form = {
    size: '',
    bgColor: '',
  };
  sizes = TextSizeOptions;
  colors = [];

  onSizeChange(val) {
    this.selectChange.emit({ tagId: PopupTagId.NavText, data: { size: val } });
  }
}
