<div class="flex-center-col" role="main" id="home-page">
  <div class="card highlight-card !mt-10" id="home-page-title">
    <span>{{ title }} is running!</span>
  </div>
  <nav class="mt-10 mb-10 link">
    <a
      [ngStyle]="customStyles"
      routerLink="/store-generator"
      routerLinkActive="active"
      id="home-page-link-1"
    >
      Store Generator
    </a>
    <a class="ml-10" routerLink="/total-commerce" routerLinkActive="active" id="home-page-link-2">
      Total Commerce
    </a>
  </nav>
</div>
