import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { GlobalLoadingService } from 'src/app/widgets/global-loading/global-loading.service';
import { RecommendationOptions } from 'src/app/store-generator/types';

@Component({
  selector: 'app-control-panel-recommendation',
  templateUrl: './control-panel-recommendation.component.html',
  styleUrls: ['./control-panel-recommendation.component.scss'],
})
export class ControlPanelRecommendationComponent implements AfterViewInit {
  @Input() tagId = '';
  @Output() selectChange = new EventEmitter();
  form = {
    rec: '1',
  };
  recommendations = RecommendationOptions;

  constructor(private loadingService: GlobalLoadingService) {}

  ngAfterViewInit() {
    this.loadingService.show();
    setTimeout(() => {
      this.loadingService.hide();
    }, 1000);
  }

  onSelectionChange(val) {
    this.selectChange.emit({
      tagId: this.tagId,
      data: {
        category: val,
      },
    });
  }
}
