<div class="color-select">
  <button
    class="color-select__btn fd-button-override"
    fd-button
    [fdMenu]="true"
    fdType="transparent"
    glyph="circle-task"
    (click)="show = !show"
    [ngStyle]="{
      '--current-color': value || 'inherit'
    }"
  ></button>
  <ul class="color-select__list" *ngIf="show">
    <li class="color-select__list-item" *ngFor="let color of colors" (click)="onChange(color)">
      <span
        class="color-select__list-item-color"
        [ngStyle]="{
          backgroundColor: color
        }"
      ></span>
    </li>
  </ul>
</div>
