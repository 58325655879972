import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { PopupTagId, BannerTags, BannerTexts } from 'src/app/store-generator/types';
import { getRandomItemFromArray, wait } from 'src/app/common/utils';
import { PreviewControlService } from '../../preview-control/preview-control.service';
import { DataCenterService } from 'src/app/core/data-center.service';
import { GlobalLoadingService } from 'src/app/widgets/global-loading/global-loading.service';

@Component({
  selector: 'app-control-panel-banner',
  templateUrl: './control-panel-banner.component.html',
  styleUrls: ['./control-panel-banner.component.scss'],
})
export class ControlPanelBannerComponent implements OnInit, OnChanges {
  @Input() tagId = '';
  @Output() selectChange = new EventEmitter();

  tags = BannerTags;
  selectedTags: string[] = [];
  imgSrc = '';
  form = {
    title: '',
    subtitle: '',
    button: '',
  };
  formElements = [
    {
      field: 'title',
      label: 'Title',
      placeholder: '',
    },
    {
      field: 'subtitle',
      label: 'Subtitle',
      placeholder: '',
    },
    {
      field: 'button',
      label: 'Action Button Text',
      placeholder: '',
    },
  ];

  constructor(
    private previewControlService: PreviewControlService,
    private dataCenterService: DataCenterService,
    private loadingService: GlobalLoadingService,
  ) {}

  get isImg() {
    return this.tagId === PopupTagId.BannerImg;
  }
  get isText() {
    return this.tagId === PopupTagId.BannerText;
  }
  get isApplyDisabled() {
    return !this.form.title || !this.form.subtitle || !this.form.button;
  }

  ngOnInit() {
    this.previewControlService.getBannerId();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tagId']) {
      if (changes['tagId'].currentValue !== PopupTagId.BannerImg) {
        this.selectedTags = [];
        this.imgSrc = '';
        this.getBannerText();
      }
      if (changes['tagId'].currentValue !== PopupTagId.BannerText) {
        this.form = {
          title: '',
          subtitle: '',
          button: '',
        };
        this.getTags();
      }
    }
  }

  onGenerateBanner() {
    this.selectChange.emit({
      tagId: PopupTagId.BannerImg,
      data: {
        origin_id: this.previewControlService.originBannerId,
        characters: this.selectedTags,
      },
    });
  }

  async onGenerateText() {
    await wait(1000);
    const item = getRandomItemFromArray(BannerTexts);
    this.form = item;
  }

  onTagClick(tag: string) {
    if (this.selectedTags.includes(tag)) {
      this.selectedTags = this.selectedTags.filter((each) => each !== tag);
    } else {
      this.selectedTags = [...this.selectedTags, tag];
    }
  }

  getTags() {
    this.loadingService.show();
    setTimeout(() => {
      this.loadingService.hide();
    }, 1000);
  }

  getBannerText() {
    this.loadingService.show();
    this.dataCenterService.getBannerText().subscribe((data) => {
      this.form = data;
      this.loadingService.hide();
    });
  }

  async onRefreshText(field: string) {
    await wait(1000);
    const item = getRandomItemFromArray(BannerTexts);
    this.form[field] = item[field];
  }

  onInputChange(event: Event, field: string) {
    if (!field) {
      return;
    }
    const target = event.target as HTMLInputElement;
    this.form[field] = target.value;
  }

  onApply() {
    this.selectChange.emit({
      tagId: PopupTagId.BannerText,
      data: {
        title: this.form.title,
        subtitle: this.form.subtitle,
        button_text: this.form.button,
      },
    });
  }
}
