import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-preview-card',
  templateUrl: './preview-card.component.html',
  styleUrls: ['./preview-card.component.scss'],
})
export class PreviewCardComponent {
  @Output() changeLayout = new EventEmitter();

  onClose(): void {
    this.changeLayout.emit('OneColumnStartFullScreen');
  }
}
