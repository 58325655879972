<fd-dynamic-page
  class="create-campaign-page"
  size="large"
  ariaLabel="Create a Campaign"
  [autoResponsive]="false"
>
  <fd-dynamic-page-header title="Create a Campaign">
    <fd-breadcrumb>
      <fd-breadcrumb-item>
        <a fd-link href="#" (click)="createCampaignPageService.goBack($event)">{{ '< Back' }}</a>
      </fd-breadcrumb-item>
    </fd-breadcrumb>
  </fd-dynamic-page-header>
  <fd-dynamic-page-content cdkScrollable>
    <app-simple-card title="Enter Requirements" class="create-campaign-page__card">
      <div class="create-campaign-page__card-content">
        <fd-form-group>
          <div fd-form-item class="!flex-row !mt-4">
            <label class="form-item-label" fd-form-label [required]="true" for="input-1">
              Theme:
            </label>
            <fd-form-input-message-group class="!ml-2 flex-1">
              <input
                id="input-1"
                fd-form-control
                type="text"
                placeholder="Input theme"
                [state]="formState['theme']"
                [value]="form.theme"
                (input)="onThemeChange($event)"
              />
              <fd-form-message
                id="message-input-1"
                type="error"
                *ngIf="formState['theme'] === 'error'"
              >
                Theme is required.
              </fd-form-message>
            </fd-form-input-message-group>
          </div>
          <div fd-form-item class="!flex-row !mt-4">
            <label class="form-item-label" fd-form-label [required]="true" for="input-2">
              Duration Time:
            </label>
            <fd-form-input-message-group class="!ml-2 flex-1">
              <input
                id="input-2"
                fd-form-control
                type="text"
                placeholder="Input duration time"
                [state]="formState['duration']"
                [value]="form.duration"
                (input)="onDurationChange($event)"
              />
              <fd-form-message
                id="message-input-2"
                type="error"
                *ngIf="formState['duration'] === 'error'"
              >
                Duration Time is required.
              </fd-form-message>
            </fd-form-input-message-group>
          </div>
          <div fd-form-item class="!flex-row !mt-4">
            <label class="form-item-label" fd-form-label [required]="true" for="textarea-1">
              Description:
            </label>
            <fd-form-input-message-group class="!ml-2 flex-1">
              <textarea
                id="textarea-1"
                fd-form-control
                placeholder="Input description"
                aria-required="true"
                rows="8"
                [state]="formState['description']"
                [value]="form.description"
                (input)="onDescriptionChange($event)"
              ></textarea>
              <fd-form-message
                id="message-textarea-1"
                type="error"
                *ngIf="formState['description'] === 'error'"
              >
                Description is required.
              </fd-form-message>
            </fd-form-input-message-group>
          </div>
        </fd-form-group>
      </div>
    </app-simple-card>
  </fd-dynamic-page-content>

  <fd-dynamic-page-footer>
    <div fd-bar barDesign="floating-footer">
      <div fd-bar-right>
        <fd-button-bar label="Create" fdType="emphasized" (click)="onCreate()"></fd-button-bar>
        <fd-button-bar label="Cancel" fdType="transparent" (click)="onCancel()"></fd-button-bar>
      </div>
    </div>
  </fd-dynamic-page-footer>
</fd-dynamic-page>
