import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { GlobalLoadingService } from 'src/app/widgets/global-loading/global-loading.service';
import { ControlPanelProductService } from './control-panel-product.service';
import { PopupTagId, ProductTags } from 'src/app/store-generator/types';

type ProductImg = {
  id: string;
  url: string;
};

@Component({
  selector: 'app-control-panel-product',
  templateUrl: './control-panel-product.component.html',
  styleUrls: ['./control-panel-product.component.scss'],
})
export class ControlPanelProductComponent implements OnInit {
  @Input() tagId = '';
  @Output() selectChange = new EventEmitter();

  form = {
    size: '',
    bgColor: '',
  };
  productImgs: ProductImg[] = [];
  selected!: ProductImg;
  tags = ProductTags;
  selectedTags: number[] = [];
  files: File[] = [];

  constructor(
    private loadingService: GlobalLoadingService,
    private controlPanelProductService: ControlPanelProductService,
  ) {}

  ngOnInit() {
    this.controlPanelProductService.prodId$.subscribe((newProdId) => {
      setTimeout(() => {
        this.getProducts();
      }, 0);
    });
  }

  getProducts() {
    this.loadingService.show();
    this.controlPanelProductService.getProducts().subscribe((data) => {
      this.productImgs = data;
      this.loadingService.hide();
    });
  }

  onTagClick(tag: number) {
    if (this.selectedTags.includes(tag)) {
      this.selectedTags = this.selectedTags.filter((each) => each !== tag);
    } else {
      this.selectedTags = [...this.selectedTags, tag];
    }
  }

  onGenerate() {
    this.loadingService.show();
    this.controlPanelProductService.getNewProduct(this.selectedTags[0]).subscribe((data) => {
      this.productImgs.push(data);
      this.loadingService.hide();
    });
  }

  onFileChanged(files: File[]): void {
    this.loadingService.show();
    this.controlPanelProductService.uploadImg(files[0]).subscribe((data) => {
      this.productImgs.push(data);
      this.loadingService.hide();
    });
  }

  onClick(img: ProductImg) {
    this.selected = img;
    this.selectChange.emit({
      tagId: PopupTagId.ProductImg,
      data: {
        origin_id: this.controlPanelProductService.getOriginalProdId(),
        new_id: img.id,
        url: img.url,
      },
    });
  }
}
