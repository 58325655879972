<div class="simple-card flex flex-col" [ngClass]="customClass">
  <div fd-bar class="simple-card-header">
    <div fd-bar-left>
      <fd-bar-element>
        <h5 fd-title>{{ title }}</h5>
      </fd-bar-element>
    </div>
    <div fd-bar-right>
      <fd-bar-element>
        <ng-content select="[header]"></ng-content>
      </fd-bar-element>
    </div>
  </div>
  <div class="simple-card-content">
    <ng-content></ng-content>
  </div>
</div>
