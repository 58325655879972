import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ControlPanelProductService {
  private prodIdSubject = new BehaviorSubject<string>('');
  prodId$ = this.prodIdSubject.asObservable();

  constructor(private http: HttpClient) {}

  get prodId() {
    return this.prodIdSubject.value;
  }

  getOriginalProdId() {
    return this.prodIdSubject.value;
  }

  updateOriginalProdId(id: string) {
    this.prodIdSubject.next(id);
  }

  getProducts(): Observable<any> {
    return this.http
      .get<any>('/api/flow-riser-backend/edit/new/product/list', {
        params: { origin_id: this.prodId },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError),
      );
  }

  getNewProduct(n: number): Observable<any> {
    return this.http
      .get<any>('/api/flow-riser-backend/edit/new/product/generate', {
        params: { origin_id: this.prodId, n },
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError),
      );
  }

  uploadImg(img: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', img);
    return this.http
      .post<any>(`/api/flow-riser-backend/edit/new/product/upload?id=${this.prodId}`, formData)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError),
      );
  }

  private handleError(res: HttpErrorResponse | any) {
    console.error(res.error || res.body.error);
    return throwError(() => new Error(res.error || 'Server error'));
  }
}
