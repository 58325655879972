<div class="flex flex-col space-y-2" *ngIf="!isTemplateSelected">
  <fdp-search-field [disableRefresh]="true"></fdp-search-field>
  <figure fd-illustrated-message [svgConfig]="sceneConfig" *ngIf="templates.length === 0">
    <figcaption fd-illustrated-message-figcaption>
      <h3 fd-illustrated-message-title>Let's get some results</h3>
      <p fd-illustrated-message-text>Start by providing your search criteria.</p>
    </figcaption>
    <fd-illustrated-message-actions>
      <button fd-button label="Reload"></button>
    </fd-illustrated-message-actions>
  </figure>
  <fd-grid-list *ngIf="templates.length > 0">
    <fd-grid-list-item *ngFor="let template of templates" (press)="onTemplateSelect()">
      <ng-template fd-grid-list-item-body>
        <img width="195" height="266" src="assets/images/cup.png" (click)="onTemplateSelect()" />
      </ng-template>
    </fd-grid-list-item>
  </fd-grid-list>
</div>
<div class="flex flex-col space-y-2" *ngIf="isTemplateSelected">
  <button fd-button label="Back to Resources" (click)="onBackToTemplates()" class="w-40"></button>
  <div class="flex flex-row space-x-2">
    <img width="195" height="266" src="assets/images/cup.png" />
    <div class="w-full">
      <div fd-form-item>
        <label fd-form-label>Campaign Name:</label>
        <fd-input-group> </fd-input-group>
      </div>

      <div fd-form-item>
        <label fd-form-label for="input-area" id="fd-input-group-label-2">Subtitle:</label>
        <textarea
          id="input-area"
          class="text-area-input"
          fd-form-control
          fdCompact
          aria-required="true"
        ></textarea>
      </div>
    </div>
  </div>
</div>
