import { Component } from '@angular/core';
import { FormStates } from '@fundamental-ngx/cdk';
import { CreateCampaignPageService } from './create-campaign-page.service';

@Component({
  selector: 'app-create-campaign-page',
  templateUrl: './create-campaign-page.component.html',
  styleUrls: ['./create-campaign-page.component.scss'],
})
export class CreateCampaignPageComponent {
  form = {
    theme: '1',
    duration: '2',
    description: '3',
  };
  formState: Record<string, FormStates> = {
    theme: 'default',
    duration: 'default',
    description: 'default',
  };
  constructor(public createCampaignPageService: CreateCampaignPageService) {}

  onThemeChange(event) {
    const { value = '' } = event.target;
    this.form.theme = value;
    this.createCampaignPageService.updateState('theme', value, this.formState);
  }

  onDurationChange(event) {
    const { value = '' } = event.target;
    this.form.duration = value;
    this.createCampaignPageService.updateState('duration', value, this.formState);
  }

  onDescriptionChange(event) {
    const { value = '' } = event.target;
    this.form.description = value;
    this.createCampaignPageService.updateState('description', value, this.formState);
  }

  onCreate() {
    if (!this.createCampaignPageService.validateAll(this.form, this.formState)) {
      return;
    }
    this.createCampaignPageService.goPreviewPage();
  }

  onCancel() {}
}
