<div class="mt-5">
  <label fd-form-label for="select-size">Size:</label>
  <fd-select
    id="select-size"
    class="w-full"
    placeholder="Select"
    [(ngModel)]="form.size"
    (ngModelChange)="onSizeChange($event)"
  >
    <ng-container *ngFor="let each of sizes">
      <li fd-option [value]="each.value">{{ each.text }}</li>
    </ng-container>
  </fd-select>
</div>
<div class="mt-3">
  <label fd-form-label for="select-color">Color:</label>
  <div class="mt-2">
    <app-color-select
      id="select-color"
      [colors]="colors"
      [(value)]="form.bgColor"
    ></app-color-select>
  </div>
</div>
