import { Component } from '@angular/core';

type File = {
  fileName: string;
  size: number;
};

@Component({
  selector: 'app-manual-upload',
  templateUrl: './manual-upload.component.html',
  styleUrls: ['./manual-upload.component.scss'],
})
export class ManualUploadComponent {
  uploadCollectionConfig = {
    scene: {
      url: 'assets/images/sapIllus-Scene-UploadCollection.svg',
      id: 'sapIllus-Scene-UploadCollection',
    },
    dialog: {
      url: 'assets/images/sapIllus-Dialog-UploadCollection.svg',
      id: 'sapIllus-Dialog-UploadCollection',
    },
  };

  //files: File[] = [];
  files: File[] = [{ fileName: 'File_Name_1', size: 100 }];
}
