import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  title = 'flow-riser-ui';
  constructor() {}

  public customStyles = {
    'margin-right': '15px',
  };
}
