import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerPageComponent } from './container-page/container-page.component';
import { CreateStorePageComponent } from './create-store-page/create-store-page.component';
import { PreviewStorePageComponent } from './preview-store-page/preview-store-page.component';
import { OptimizeStorePageComponent } from './optimize-store-page/optimize-store-page.component';
import { CreateCampaignPageComponent } from './optimize-store-page/create-campaign-page/create-campaign-page.component';

const routes: Routes = [
  {
    path: 'store-generator',
    component: ContainerPageComponent,
    children: [
      {
        path: 'create',
        component: CreateStorePageComponent,
      },
      {
        path: 'preview',
        component: PreviewStorePageComponent,
      },
      {
        path: 'optimize',
        component: OptimizeStorePageComponent,
      },
      {
        path: 'create-campaign',
        component: CreateCampaignPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StoreGeneratorRoutingModule {}
