import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageBoxService } from '@fundamental-ngx/core/message-box';

import { NavigationService } from 'src/app/core/navigation.service';
import { GlobalLoadingService } from 'src/app/widgets/global-loading/global-loading.service';
import { DataCenterService } from 'src/app/core/data-center.service';
import { wait } from 'src/app/common/utils';
import { PreviewStorePageService } from './preview-store-page.service';
import { PreviewScreenComponent } from './preview-screen/preview-screen.component';
import { PreviewControlComponent } from './preview-control/preview-control.component';
import { PreviewControlService } from './preview-control/preview-control.service';
import { ControlPanelProductService } from './components/control-panel-product/control-panel-product.service';
import { PopupTag, PopupTagId, HotZone, HotZoneId } from '../types';

@Component({
  selector: 'app-preview-store-page',
  templateUrl: './preview-store-page.component.html',
  styleUrls: ['./preview-store-page.component.scss'],
})
export class PreviewStorePageComponent implements OnInit {
  @ViewChild(PreviewScreenComponent) previewScreenComponent!: PreviewScreenComponent;
  @ViewChild(PreviewControlComponent) previewControlComponent!: PreviewControlComponent;
  loaded = false;
  isEditing = this.dataCenterService.isEditing$;
  shopName!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigation: NavigationService,
    private loadingService: GlobalLoadingService,
    private dataCenterService: DataCenterService,
    private messageBoxService: MessageBoxService,
    private previewStorePageService: PreviewStorePageService,
    private previewControlService: PreviewControlService,
    private controlPanelProductService: ControlPanelProductService,
  ) {}

  get isFunshop() {
    return this.shopName && this.shopName === 'funshop';
  }

  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const { shop } = params;
      this.shopName = shop;
      this.dataCenterService.updateShopName(shop);
    });
    setTimeout(() => {
      this.loaded = true;
    }, 2000);
  }

  goBack(event) {
    event.preventDefault();
    this.navigation.back();
  }

  goOptimizePage() {
    this.router.navigate(['/store-generator/optimize']);
  }

  onEdit() {
    this.dataCenterService.updateEditing(true);
    this.updateHotZone();
    // this.updateComponentPosition();
  }

  onPopupClick({ hotZone, tag }: { hotZone: HotZone; tag: PopupTag }) {
    // 编辑 product 时要记录当前 product 的id，该 id 来自图片的 url
    if (hotZone.id === HotZoneId.Product) {
      if (hotZone.customId) {
        this.controlPanelProductService.updateOriginalProdId(hotZone.customId);
      }
    }
    this.previewControlComponent.onUpdate(hotZone.id, tag);
    this.onEdit();
  }

  onPopupClose() {
    // this.previewControlComponent.onClose();
  }

  async updateHotZone() {
    if (this.previewScreenComponent) {
      await wait(500);
      this.previewScreenComponent.updateHotZone();
      await wait(1000);
      this.previewScreenComponent.updateHotZone();
    }
  }
  async updateComponentPosition() {
    if (this.previewScreenComponent) {
      await wait(500);
      this.previewScreenComponent.updateComponentPosition();
    }
  }

  // 右边提交更改
  async onApply(val) {
    this.loadingService.show();
    this.previewStorePageService.apply(val).subscribe((data) => {
      console.log('Data received:', data);
      this.refreshIframe(val, data);
      this.previewScreenComponent.removeComponent(this.previewScreenComponent.container);
      this.loadingService.hide();
    });
  }

  async onPublish() {
    this.loadingService.show();
    await wait(1000);
    this.loadingService.hide();
    if (this.isFunshop) {
      const messageBoxRef = this.messageBoxService.open(
        {
          title: 'Success',
          content: 'Storefront “Fun Shop” was created.',
          approveButton: 'Ok',
          approveButtonCallback: () => {
            messageBoxRef.close('Approved');
            this.toStorePage();
          },
        },
        {
          type: 'success',
          showSemanticIcon: true,
        },
      );
    } else {
      this.toStorePage();
      const messageBoxRef = this.messageBoxService.open(
        {
          title: 'Success',
          content: 'Storefront “Fun Shop” was created.',
          approveButton: 'Check out Joule Recommendation',
          approveButtonCallback: () => {
            messageBoxRef.close('Approved');
            // @ts-ignore
            window.testPageFunctions?.show?.call();
          },
        },
        {
          type: 'success',
          showSemanticIcon: true,
        },
      );
    }
  }

  onCancel() {
    if (this.isEditing) {
      this.dataCenterService.updateEditing(false);
      // this.updateComponentPosition();
      this.previewControlComponent.onClose();
      this.previewScreenComponent.removeComponent(this.previewScreenComponent.container);
      this.updateHotZone();
    }
  }

  toStorePage() {
    const link = document.createElement('a');
    // link.href = 'https://flowriser.dev.eureka.dev.sap/funshop/';
    link.href = 'https://flowriser.dev.eureka.dev.sap/chrishop/streamlit/';
    link.target = '_blank';
    link.click();
  }

  // 本地改iframe
  refreshIframe({ tagId, data }: { tagId: string; data: any }, response: any) {
    const Iframe = document.getElementById('preview-frame') as HTMLIFrameElement;
    if (!Iframe) {
      throw new Error('Cannot find iframe');
    }
    const IfDocument = Iframe.contentDocument as Document;
    if (tagId && tagId === PopupTagId.NavText) {
      this.dataCenterService.updateNavFontSize(IfDocument, data);
    }
    if (tagId && tagId === PopupTagId.BannerImg) {
      this.dataCenterService.updateBannerImg(IfDocument, response);
    }
    if (tagId && tagId === PopupTagId.BannerText) {
      this.dataCenterService.updateBannerText(IfDocument, data);
    }
    if (tagId && tagId === PopupTagId.RecProduct) {
      this.dataCenterService.updateRecommendations(Iframe);
    }
    if (tagId && tagId === PopupTagId.ProductImg) {
      this.dataCenterService.updateProductImg(IfDocument, data);
    }
  }

  syncWithDesign() {
    this.loadingService.show();
    const url =
      'www.figma.com/file/SHezMaHUWGYTGD67ZGw4Ub/FlowRiserXFigma?type=design&node-id=0-1&mode=design&t=Ax6mi6AuHyXYBXun-0';
    this.previewStorePageService.syncFigma(url).subscribe((data) => {
      this.loadingService.hide();
    });
  }
}
