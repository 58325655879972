import { Injectable } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { MessageToastService } from '@fundamental-ngx/core/message-toast';

export const enum HttpErrorMessage {
  NOT_AUTHENTICATED = 'You are not authenticated. Please login.',
  NOT_AUTHORIZED = 'You are not allowed to perform this action.',
  NOT_FOUND = 'The requested resource was not found.',
  UNKNOWN = 'Something went wrong. Please try again later.',
}

@Injectable({
  providedIn: 'root',
})
export class HttpErrorService {
  constructor(private messageToastService: MessageToastService) {}

  handleError(statusCode: HttpStatusCode): void {
    const msg = this.getErrorMessage(statusCode);
    this.messageToastService.open(msg, {
      duration: 3000,
    });
    console.log(statusCode, msg);
  }

  private getErrorMessage(statusCode: HttpStatusCode): HttpErrorMessage {
    let message;
    switch (statusCode) {
      case HttpStatusCode.Unauthorized:
        message = HttpErrorMessage.NOT_AUTHENTICATED;
        break;
      case HttpStatusCode.Forbidden:
        message = HttpErrorMessage.NOT_AUTHORIZED;
        break;
      case HttpStatusCode.NotFound:
        message = HttpErrorMessage.NOT_FOUND;
        break;
      default:
        message = HttpErrorMessage.UNKNOWN;
    }

    return message;
  }
}
