import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FundamentalNgxCdkModule } from '@fundamental-ngx/cdk';
import { FundamentalNgxCoreModule, FdDatetimeModule } from '@fundamental-ngx/core';
import { FundamentalNgxPlatformModule } from '@fundamental-ngx/platform';
import { HttpClientModule } from '@angular/common/http';
import { CdkTableModule } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SocialPageComponent } from './social-page/social-page.component';
import { CreateCardComponent } from './create-card/create-card.component';
import { PreviewCardComponent } from './preview-card/preview-card.component';
import { TotalCommerceRoutingModule } from './total-commerce-routing.module';
import { PublishedCompaignsComponent } from './published-compaigns/published-compaigns.component';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { FashionIconComponent } from './step2/fashion-icon/fashion-icon.component';
import { ManualUploadComponent } from './step2/manual-upload/manual-upload.component';
@NgModule({
  declarations: [
    SocialPageComponent,
    CreateCardComponent,
    PreviewCardComponent,
    PublishedCompaignsComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    FashionIconComponent,
    ManualUploadComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FundamentalNgxCdkModule,
    FundamentalNgxCoreModule,
    FdDatetimeModule,
    FundamentalNgxPlatformModule,
    HttpClientModule,
    CdkTableModule,
    DragDropModule,
    TotalCommerceRoutingModule,
  ],
})
export class TotalCommerceModule {}
