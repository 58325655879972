import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FlexibleColumnLayout } from '@fundamental-ngx/core/flexible-column-layout';

@Component({
  selector: 'app-tc-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class Step2Component implements OnInit {
  selectedCampaignResource = 'fashionIcon';

  @Output() setCompleted = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.changeLayout('TwoColumnsStartExpanded');
    //@TODO
    // this.setCompleted.emit(true);
  }

  @ViewChild('overlay')
  overlay!: ElementRef<HTMLElement>;

  fullscreen = false;

  localLayout: FlexibleColumnLayout = 'OneColumnStartFullScreen';

  changeLayout(newValue: FlexibleColumnLayout): void {
    this.localLayout = newValue;
  }

  enterFullscreenExample(): void {
    this.fullscreen = true;
    this.overlay.nativeElement.style.width = '100%';
  }

  exitFullscreenExample(event: Event): void {
    event.stopPropagation();
    this.fullscreen = false;
    this.overlay.nativeElement.style.width = '0%';
  }
}
