<div class="preview-control bg-white box-border relative">
  <!-- <ng-template [ngIf]="isLoading | async" [ngIfElse]="content"> -->
  <ng-template [ngIf]="isLoading | async">
    <div class="preview-control-overlay flex-center">
      <fd-busy-indicator [loading]="true" size="m"></fd-busy-indicator>
    </div>
  </ng-template>
  <!-- <ng-template #content> -->
  <div class="preview-control-header">{{ title }}</div>
  <div class="preview-control-controls">
    <div [ngSwitch]="currentZone">
      <div *ngSwitchCase="navigatorZone">
        <app-control-panel-navigator
          [tagId]="currentTag"
          (selectChange)="onApply($event)"
        ></app-control-panel-navigator>
      </div>
      <div *ngSwitchCase="bannerZone">
        <app-control-panel-banner
          [tagId]="currentTag"
          (selectChange)="onApply($event)"
        ></app-control-panel-banner>
      </div>
      <div *ngSwitchCase="recommendationZone">
        <app-control-panel-recommendation
          [tagId]="currentTag"
          (selectChange)="onApply($event)"
        ></app-control-panel-recommendation>
      </div>
      <div *ngSwitchCase="productZone">
        <app-control-panel-product
          [tagId]="currentTag"
          (selectChange)="onApply($event)"
        ></app-control-panel-product>
      </div>
      <div *ngSwitchDefault>
        <div class="preview-control-empty">
          <img [src]="emptyImg" alt="" />
        </div>
      </div>
    </div>

    <div class="felx flex-col preview-control-popup" *ngIf="tags.length">
      <div class="flex justify-between">
        <b class="text-base">Maybe Next?</b>
        <button fd-button fdType="transparent">View all settings</button>
      </div>
      <div class="flex-1 flex flex-col justify-between mt-2.5">
        <div class="preview-control-popup-row" *ngFor="let tag of tags">
          <button
            fd-button
            class="fd-button-override preview-control-popup-tag"
            [id]="tag.id"
            (click)="onUpdate(currentZone, tag)"
          >
            {{ tag.text }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- </ng-template> -->
</div>
