<div class="preview-screen bg-white box-border flex flex-col">
  <div class="preview-screen-container" #container>
    <div class="preview-screen-wrapper">
      <ng-template [ngIf]="isEditing$ | async">
        <ng-container *ngFor="let hotZone of hotZones; let i = index">
          <div
            class="preview-screen-hotZone"
            [id]="hotZone.id"
            [attr.data-id]="hotZone.customId"
            [ngStyle]="hotZone.style"
            (mouseenter)="onMouseEnter($event, hotZone)"
            (mouseleave)="onMouseLeave($event, hotZone)"
          >
            <span *ngIf="hotZone.isActive" class="preview-screen-hotZone-label">
              {{ hotZone.label }}
            </span>
          </div>
        </ng-container>

        <div id="popup-container" #popupContainer></div>
      </ng-template>
      <ng-template [ngIf]="isFunshop" [ngIfElse]="chrishop">
        <iframe
          #previewIframe
          id="preview-frame"
          src="/funshop/"
          width="100%"
          height="100%"
          frameborder="0"
          (load)="onIframeLoad($event)"
        >
        </iframe>
      </ng-template>
      <ng-template #chrishop>
        <iframe
          #previewIframe
          id="preview-frame"
          src="/chrishop/streamlit/"
          width="100%"
          height="100%"
          frameborder="0"
          (load)="onIframeLoad($event)"
        >
        </iframe>
      </ng-template>
    </div>
  </div>
</div>
