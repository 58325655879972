import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageToastService } from '@fundamental-ngx/core/message-toast';

import { GlobalLoadingService } from 'src/app/widgets/global-loading/global-loading.service';
import { ContainerPageService } from './container-page.service';
import { LocalStorageService } from 'src/app/core/local-storage.service';

@Component({
  selector: 'app-store-generator-container-page',
  templateUrl: './container-page.component.html',
  styleUrls: ['./container-page.component.scss'],
})
export class ContainerPageComponent implements OnInit {
  loaded = false;
  isStoreCreated = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public messageToastService: MessageToastService,
    private loadingService: GlobalLoadingService,
    private containerPageService: ContainerPageService,
    private localStorageService: LocalStorageService,
  ) {}

  async ngOnInit() {
    // this.localStorageService.setItem('isStoreCreated', true);
    // this.localStorageService.setItem('needOptimization', true);
    this.getStores();
  }

  getStores() {
    this.loadingService.show();
    this.containerPageService.getStores().subscribe((data) => {
      console.log('Data received:', data);
      this.isStoreCreated = Boolean(this.localStorageService.getItem('isStoreCreated'));
      this.loaded = true;
      this.loadingService.hide();
      this.toNextPage();
    });
  }

  open(msg: string): void {
    this.messageToastService.open(msg, {
      duration: 3000,
    });
  }

  toNextPage() {
    if (
      location.pathname !== '/store-generator' &&
      location.pathname !== '/store-generator/create'
    ) {
      return;
    }
    if (this.isStoreCreated) {
      this.router.navigate(['/store-generator/optimize'], { replaceUrl: true });
    } else {
      this.router.navigate(['/store-generator/create'], { replaceUrl: true });
    }
  }
}
