export enum RadioType {
  UseExistingWebsite = '1',
  UseDefaultLayout = '2',
  UseDesignFile = '3',
}

export enum CreateStoreSteps {
  Create = '1',
  Preview = '2',
}

export type HotZone = {
  id: string;
  label: string;
  isActive: boolean;
  area: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  style: {
    top?: string;
    bottom?: string;
    right?: string;
    left?: string;
    width?: string;
    height?: string;
    border?: string;
  };
  customId?: string;
};

export type TargetNode = {
  id: string;
  selector: string | (string | number)[];
  index: number;
  label: string;
  style?: Record<string, string>;
  genCustomId?: (elm: HTMLElement) => string;
};

export type PopupTag = {
  id: string;
  text: string;
};

export enum HotZoneId {
  Navigator = 'navigator',
  Banner = 'banner',
  Recommendation = 'recommendation',
  Product = 'product',
}

export enum PopupTagId {
  NavText = '0',
  NavBackground = '1',
  BannerImg = '2',
  BannerText = '3',
  RecProduct = '4',
  RecNumber = '5',
  ProductImg = '6',
  Product = '7',
}

export enum PopupTagsText {
  NavText = 'Change the navigator text',
  NavBackground = 'Change the navigator background',
  BannerImg = 'Change the banner image',
  BannerText = 'Change the banner text',
  RecProduct = 'Change the recommended products',
  RecNumber = 'Change the number of the products',
  ProductImg = 'Change the image of the product',
  Product = 'Change a product',
}

export const CONFIG = {
  [HotZoneId.Navigator]: {
    tags: [
      {
        id: PopupTagId.NavText,
        text: PopupTagsText.NavText,
      },
      {
        id: PopupTagId.NavBackground,
        text: PopupTagsText.NavBackground,
      },
    ],
  },
  [HotZoneId.Banner]: {
    tags: [
      {
        id: PopupTagId.BannerImg,
        text: PopupTagsText.BannerImg,
      },
      {
        id: PopupTagId.BannerText,
        text: PopupTagsText.BannerText,
      },
    ],
  },
  [HotZoneId.Recommendation]: {
    tags: [
      {
        id: PopupTagId.RecProduct,
        text: PopupTagsText.RecProduct,
      },
      {
        id: PopupTagId.RecNumber,
        text: PopupTagsText.RecNumber,
      },
    ],
  },
  [HotZoneId.Product]: {
    tags: [
      {
        id: PopupTagId.ProductImg,
        text: PopupTagsText.ProductImg,
      },
      {
        id: PopupTagId.Product,
        text: PopupTagsText.Product,
      },
    ],
  },
};

export const TextSizeOptions = [
  { text: '12px', value: '12px' },
  { text: '13px', value: '13px' },
  { text: '14px', value: '14px' },
  { text: '15px', value: '15px' },
  { text: '16px', value: '16px' },
];

export const BannerTags = [
  'Colorful',
  'Cheerful',
  'Dynamic',
  'Vibrant',
  'Playful',
  'Cozy',
  'Modern',
  'Fresh',
  'Sale',
];

export const BannerTexts = [
  {
    title: 'Halloween',
    subtitle: 'Trick or treat!',
    button: 'Shop Now!',
  },
  {
    title: 'Spoil Your Taste!',
    subtitle: 'Unique Groceries Awaits You',
    button: 'Start Now',
  },
  {
    title: 'Alluring Decor',
    subtitle: `Transform Your House's Aura`,
    button: 'Dive in',
  },
  {
    title: 'Time for Toys',
    subtitle: 'Interactive and Engaging Fun',
    button: 'Play More',
  },
  { title: 'Relish the Variety', subtitle: 'Your Online Supermarket', button: 'Buy Now' },
  { title: 'Love Your Home', subtitle: 'Décor Items to Adore', button: 'Shop Now' },
  { title: 'Food Adventure', subtitle: 'Eats from Around the Globe', button: 'Eat Well' },
  { title: 'Remarkable Range', subtitle: 'From Food to Fun and All', button: 'Exciting' },
  { title: 'Gastronomy Galore', subtitle: 'Interesting Eats Here!', button: 'Discover' },
  { title: 'Game-Time Surprises', subtitle: 'Interesting Toys for Kids', button: 'Buy Toys' },
  { title: 'Intriguing Groceries', subtitle: 'The Appeal of Variety', button: 'Shop More' },
  { title: 'Plethora of Items', subtitle: 'Endless Range of Groceries', button: 'See Now' },
  { title: 'Transform Your Space', subtitle: 'Decor That Will Amaze', button: 'Explore' },
  { title: 'Stellar Supermarket', subtitle: 'Everything You Need And More', button: 'Start Now' },
  { title: 'Liven Up Mealtime', subtitle: 'Groceries That Excite', button: 'See More' },
  { title: 'Playful Possibilities', subtitle: 'Toys To Capture Imagination', button: 'Get Toys' },
  { title: 'Your Lifestyle Store', subtitle: 'Think It, Find It Here', button: 'Browse Now' },
  { title: 'Cherish Your Home', subtitle: 'Decor For Every Corner', button: 'Buy Decor' },
  { title: 'Get Cooking!', subtitle: 'Groceries To Ignite Passion', button: 'Buy Now' },
  { title: `Child's Playtime`, subtitle: 'Toys For Joy and Learning', button: 'Find Toys' },
  { title: 'Classy Collections', subtitle: 'Groceries To Home Decor', button: 'Start Shop' },
];

export const RecommendationOptions = [
  {
    text: 'Best selling products',
    value: '1',
  },
  {
    text: 'Most profitable products',
    value: '2',
  },
  {
    text: 'Hero products',
    value: '3',
  },
  {
    text: 'Most viewed products',
    value: '4',
  },
];

export const ProductTags = [
  { value: 1, text: 'Festival' },
  { value: 2, text: 'Ordinary' },
  { value: 3, text: 'Colorful' },
  { value: 4, text: 'Nature' },
];
