import { Component, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-color-select',
  templateUrl: './color-select.component.html',
  styleUrls: ['./color-select.component.scss'],
})
export class ColorSelectComponent {
  @Input() colors: string[] = [];
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  show = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.show) {
      return;
    }
    if (!this.elementRef?.nativeElement) {
      return;
    }
    const target = event.target as HTMLElement;
    const colorSelectElement = this.elementRef.nativeElement;
    if (colorSelectElement !== target && !colorSelectElement.contains(target)) {
      console.log('Clicked outside of the component');
      this.show = false;
    }
  }

  onChange(color: string) {
    this.show = false;
    this.valueChange.emit(color);
  }
}
