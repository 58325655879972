import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpAuthService } from './http-auth.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private httpAuthService: HttpAuthService) {}
  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    return this.httpAuthService.getAuthToken().pipe(
      switchMap((token: string) => {
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            Cookie: token,
          },
        });
        return next.handle(authReq);
      }),
    );
  }
}
