import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoadingService {
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  count = 0;

  show(): void {
    this.count++;
    this.isLoadingSubject.next(true);
  }

  hide(): void {
    if (this.count > 0) {
      this.count--;
    }
    if (this.count === 0) {
      this.isLoadingSubject.next(false);
    }
  }
}
