import { Component } from '@angular/core';
import { GlobalLoadingService } from './global-loading.service';

@Component({
  selector: 'app-global-loading',
  templateUrl: './global-loading.component.html',
  styleUrls: ['./global-loading.component.scss'],
})
export class GlobalLoadingComponent {
  isLoading$ = this.loadingService.isLoading$;
  constructor(private loadingService: GlobalLoadingService) {}
}
