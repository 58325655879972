/* eslint-disable @angular-eslint/no-output-native */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  ElementRef,
  Renderer2,
  HostListener,
} from '@angular/core';
import { PopupTag } from 'src/app/store-generator/types';

@Component({
  selector: 'app-preview-popup',
  templateUrl: './preview-popup.component.html',
  styleUrls: ['./preview-popup.component.scss'],
})
export class PreviewPopupComponent implements OnInit, AfterViewInit {
  @Input() tags: PopupTag[] = [];
  @Output() select = new EventEmitter<PopupTag>();
  @Output() close = new EventEmitter<PopupTag>();
  loaded = false;
  title = 'You may want to';
  buttonText = 'View all settings';

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.loaded = true;
    }, 1000);
  }

  ngAfterViewInit() {
    this.checkPosition();
  }

  onSelect(tag: PopupTag) {
    this.select.emit(tag);
  }

  onClose() {
    this.close.emit();
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.elementRef?.nativeElement) {
      return;
    }
    const target = event.target as HTMLElement;
    const self = this.elementRef.nativeElement;
    if (self !== target && !self.contains(target)) {
      console.log('Clicked outside of the component');
      this.onClose();
    }
  }

  checkPosition() {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (window.getComputedStyle(element).position === 'absolute') {
        const parentElement = element.parentElement;
        const parentRect = parentElement.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        if (elementRect.left < parentRect.left) {
          this.renderer.setStyle(element, 'left', `10px`);
          this.renderer.removeStyle(element, 'right');
        }
        if (elementRect.left + elementRect.width > parentRect.left + parentRect.width) {
          this.renderer.setStyle(element, 'right', `10px`);
          this.renderer.removeStyle(element, 'left');
        }
        // if (elementRect.top < parentRect.top) {
        //   this.renderer.setStyle(element, 'top', `10px`);
        //   this.renderer.removeStyle(element, 'bottom');
        // }
        // if (elementRect.top + elementRect.height > parentRect.top + parentRect.height) {
        //   this.renderer.setStyle(element, 'bottom', `10px`);
        //   this.renderer.removeStyle(element, 'top');
        // }
      }
    }, 0);
  }
}
