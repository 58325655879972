<div class="campaign-optimization">
  <div class="flex justify-end">
    <button fd-button fdType="transparent" (click)="createCampaign()">Create a Campaign</button>
  </div>
  <div class="flex mt-7">
    <div class="flex-[1]">
      <div class="campaign-optimization__card">
        <div class="flex items-center">
          <fd-icon
            ng-reflect-glyph="calendar"
            class="sap-icon--calendar campaign-optimization__card-icon"
          ></fd-icon>
          <div class="ml-3 flex flex-col">
            <span class="campaign-optimization__card-title">Calendar</span>
            <!-- <span class="campaign-optimization__card-subtitle">Across 186 stores</span> -->
          </div>
        </div>
        <fd-calendar
          class="campaign-optimization__calendar"
          fdCompact="compact"
          [showWeekNumbers]="true"
          [markWeekends]="true"
        ></fd-calendar>
      </div>
    </div>
    <div class="flex-[2] ml-9">
      <div class="campaign-optimization__card">
        <div class="flex items-center">
          <fd-icon
            glyph="calendar"
            class="sap-icon--calendar campaign-optimization__card-icon"
          ></fd-icon>
          <div class="ml-3 flex flex-col">
            <span class="campaign-optimization__card-title"
              >Recommended Festival with Campaign</span
            >
            <!-- <span class="campaign-optimization__card-subtitle">3 events</span> -->
          </div>
          <button class="!ml-auto" fd-button fdType="transparent">View All</button>
        </div>
        <ul class="campaign-optimization__eventlist">
          <li
            class="campaign-optimization__eventlist-item"
            *ngFor="let event of events"
            [ngStyle]="{
              '--event-background-color': event.color
            }"
          >
            <div class="campaign-optimization__eventlist-item__left">
              <b>{{ event.date }}</b>
              <div>{{ event.name }}</div>
            </div>
            <div class="campaign-optimization__eventlist-item__right">
              <!-- <fd-icon glyph="slim-arrow-right" class="sap-icon--slim-arrow-right"></fd-icon> -->
              <button class="fd-button-override" fd-button (click)="createCampaign()">
                Create a Campaign
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
