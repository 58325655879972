<fd-dynamic-page
  class="preview-store-page"
  size="large"
  ariaLabel="Example Dynamic Page"
  [autoResponsive]="false"
>
  <fd-dynamic-page-header title="Create Storefront">
    <fd-breadcrumb>
      <fd-breadcrumb-item>
        <a fd-link href="#" (click)="goBack($event)">{{ '< Back' }}</a>
      </fd-breadcrumb-item>
    </fd-breadcrumb>
  </fd-dynamic-page-header>

  <fd-dynamic-page-content cdkScrollable class="!pb-18">
    <div class="preview-store-container">
      <!-- preview-screen -->
      <app-simple-card
        title="Preview"
        class="preview-store preview-store-container-left"
        [ngClass]="[(isEditing | async) ? 'shrink-left' : 'expand-left']"
      >
        <button
          *ngIf="!isFunshop"
          class="!mr-5"
          header
          fd-button
          fdType="transparent"
          (click)="syncWithDesign()"
        >
          Refresh
        </button>
        <button
          *ngIf="(isEditing | async) === false"
          header
          fd-button
          fdType="transparent"
          (click)="onEdit()"
        >
          Edit
        </button>
        <app-preview-screen
          #previewScreen
          (popupClick)="onPopupClick($event)"
          (popupClose)="onPopupClose()"
        ></app-preview-screen>
      </app-simple-card>
      <!-- preview-screen -->

      <!-- preview-control -->
      <app-simple-card
        title="Edit"
        class="preview-store preview-store-container-right"
        [ngClass]="[(isEditing | async) ? 'show-right' : 'hide-right']"
      >
        <app-preview-control (apply)="onApply($event)"></app-preview-control>
      </app-simple-card>
      <!-- preview-control -->
    </div>
  </fd-dynamic-page-content>

  <fd-dynamic-page-footer>
    <div fd-bar barDesign="floating-footer">
      <div fd-bar-right>
        <fd-button-bar label="Publish" fdType="emphasized" (click)="onPublish()"></fd-button-bar>
        <fd-button-bar label="Cancel" fdType="transparent" (click)="onCancel()"></fd-button-bar>
      </div>
    </div>
  </fd-dynamic-page-footer>
</fd-dynamic-page>
