import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageToastService } from '@fundamental-ngx/core/message-toast';

import { GlobalLoadingService } from 'src/app/widgets/global-loading/global-loading.service';
// import { DataCenterService } from 'src/app/core/data-center.service';
import { CreateStorePageService } from './create-store-page.service';
import { RadioType } from '../types';

@Component({
  selector: 'app-create-store-page',
  templateUrl: './create-store-page.component.html',
  styleUrls: ['./create-store-page.component.scss'],
})
export class CreateStorePageComponent {
  stores = [
    { id: 'fun-shop', name: 'Fun Shop' },
    { id: 'empty-shop', name: 'Empty Shop' },
  ];
  currentStore = 'fun-shop';
  form = { radioVal: '', url: '', category: '', design: '' };
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: GlobalLoadingService,
    private createStorePageService: CreateStorePageService, // private dataCenterService: DataCenterService,
    private messageToastService: MessageToastService,
  ) {}

  get isPreviewBtnDisabled() {
    const { form } = this;
    if (form.radioVal === RadioType.UseExistingWebsite) {
      return !form.url;
    } else if (form.radioVal === RadioType.UseDefaultLayout) {
      return !form.category;
    } else if (form.radioVal === RadioType.UseDesignFile) {
      return !form.design;
    } else {
      return true;
    }
  }

  onFormChange(form) {
    this.form = form;
  }

  async onCreate() {
    if (this.form.radioVal === RadioType.UseDesignFile) {
      // 'www.figma.com/file/SHezMaHUWGYTGD67ZGw4Ub/FlowRiserXFigma?type=design&node-id=0-1&mode=design&t=Ax6mi6AuHyXYBXun-0'
      const regex = /(?:https:\/\/)?www\.figma\.com\/file\/([a-zA-Z0-9]+)\/.*/;
      if (!regex.test(this.form.design)) {
        this.messageToastService.open('Input string is not in the correct format', {
          duration: 3000,
        });
      } else {
        this.loadingService.show();
        this.createStorePageService.syncFigma(this.form.design).subscribe((data) => {
          console.log('Data received:', data);
          this.loadingService.hide();
          this.goPreviewPage('chrishop');
        });
      }
    } else {
      this.loadingService.show();
      this.createStorePageService.createStores({ url: this.form.url }).subscribe((data) => {
        console.log('Data received:', data);
        this.loadingService.hide();
        this.goPreviewPage('funshop');
      });
    }
  }

  goPreviewPage(shop: string) {
    this.router.navigate(['/store-generator/preview'], {
      queryParams: { shop },
    });
  }

  noop(val) {
    console.log('outer', val);
  }
}
