<app-simple-card title="Create storefront" class="create-store">
  <div class="create-store-title">Select the reference to create your storefront:</div>
  <fd-form-group>
    <div fd-form-item class="!mt-3">
      <fd-radio-button
        id="radio-1"
        name="radio-1"
        value="1"
        [(ngModel)]="form.radioVal"
        (ngModelChange)="onRadioChange($event)"
      >
        Use existing brand website
      </fd-radio-button>
    </div>
    <div fd-form-item *ngIf="isUrlInputShow">
      <input
        class="!ml-7 !w-[280px]"
        fd-form-control
        type="text"
        placeholder="Input the website URL"
        [value]="form.url"
        (input)="onUrlChange($event)"
      />
    </div>
    <div fd-form-item class="!mt-5">
      <fd-radio-button
        id="radio-2"
        name="radio-2"
        value="2"
        [(ngModel)]="form.radioVal"
        (ngModelChange)="onRadioChange($event)"
      >
        Choose from default theme library
      </fd-radio-button>
    </div>
    <div fd-form-item *ngIf="isSelectShow">
      <fd-select
        class="!ml-7 w-[280px]"
        placeholder="Select a category"
        [(ngModel)]="form.category"
      >
        <li fd-option *ngFor="let option of categories" [value]="option.id">{{ option.name }}</li>
      </fd-select>
    </div>
    <div fd-form-item class="!mt-5">
      <fd-radio-button
        id="radio-3"
        name="radio-3"
        value="3"
        [(ngModel)]="form.radioVal"
        (ngModelChange)="onRadioChange($event)"
      >
        Use design file
      </fd-radio-button>
    </div>
    <div fd-form-item *ngIf="isDesignInputShow">
      <input
        class="!ml-7 !w-[280px]"
        fd-form-control
        type="text"
        placeholder="Input the figma URL"
        [value]="form.design"
        (input)="onDesignChange($event)"
      />
    </div>
  </fd-form-group>
</app-simple-card>
