import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ThemingModule, ThemingService } from '@fundamental-ngx/core/theming';

/** Modules **/
import { AppRoutingModule } from './app-routing.module';
import { StoreGeneratorModule } from './store-generator/store-generator.module';
import { TotalCommerceModule } from './total-commerce/total-commerce.module';
import { WidgetsModule } from './widgets/widgets.module';

/** Components **/
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

/** Services **/
import { NavigationService } from './core/navigation.service';
import { LocalStorageService } from './core/local-storage.service';
import { DataCenterService } from './core/data-center.service';

/** Others **/
import { HttpErrorInterceptor } from './core/http-error.interceptor';
import { HttpAuthInterceptor } from './core/http-auth.interceptor';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ThemingModule.withConfig({
      defaultTheme: 'sap_horizon',
      changeThemeOnQueryParamChange: false,
    }),
    AppRoutingModule,
    StoreGeneratorModule,
    TotalCommerceModule,
    WidgetsModule,
  ],
  declarations: [AppComponent, HomeComponent],
  providers: [
    NavigationService,
    LocalStorageService,
    DataCenterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(themingService: ThemingService) {
    themingService.init();
  }
}
