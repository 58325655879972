import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `<div class="flow-riser-ui-app-container">
    <app-global-loading></app-global-loading>
    <router-outlet></router-outlet>
  </div>`,
})
export class AppComponent {
  constructor() {}
}
