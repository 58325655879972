<div class="flex items-center justify-between">
  <div class="flex items-center">
    <label fd-form-label for="fd-select-store">Storefront:</label>
    <fd-select
      class="w-[280px]"
      id="fd-select-store"
      placeholder="Select a store"
      [(ngModel)]="value"
      (ngModelChange)="onSelectionChange($event)"
    >
      <li fd-option *ngFor="let option of stores" [value]="option.id">{{ option.name }}</li>
    </fd-select>
  </div>
  <ng-content></ng-content>
</div>
