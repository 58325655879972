import { Component, Input, Output, EventEmitter } from '@angular/core';

type Store = {
  id: string;
  name: string;
};

@Component({
  selector: 'app-store-select',
  templateUrl: './store-select.component.html',
  styleUrls: ['./store-select.component.scss'],
})
export class StoreSelectComponent {
  _value: string = '';
  @Input() stores: Store[] = [];
  @Output() valueChange = new EventEmitter<string>();
  @Output() selectionChange = new EventEmitter<Store>();

  @Input()
  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.valueChange.emit(this._value);
  }

  onSelectionChange(val: string) {
    const store = this.stores.filter((s) => !!s.id).find((s) => s.id === val);
    this.selectionChange.emit(store);
  }
}
