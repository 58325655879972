import { Component, EventEmitter, OnInit, Output, SimpleChange } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tc-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {
  selectedChannel: string = '';
  selectedPurpose: string = '';
  selectedCampaignResource: string = '';
  campaignName: string = '';
  channels = ['Instagram', 'Facebook', 'Wechat', 'Weibo'];
  purposes = ['Valentine’s Day', 'Christmas Day'];
  formTypesGroupRegister: FormGroup = new FormGroup({});

  @Output() setCompleted = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.setCompleted.emit(false);
  }

  onSelect(): void {
    if (this.selectedChannel && this.selectedPurpose && this.campaignName) {
      this.setCompleted.emit(true);
    } else {
      this.setCompleted.emit(false);
    }
  }

  onChange(event): void {
    this.campaignName = event.target.value;
    if (this.selectedChannel && this.selectedPurpose && this.campaignName) {
      this.setCompleted.emit(true);
    } else {
      this.setCompleted.emit(false);
    }
  }
}
