import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-campaign-optimization',
  templateUrl: './campaign-optimization.component.html',
  styleUrls: ['./campaign-optimization.component.scss'],
})
export class CampaignOptimizationComponent {
  events = [
    {
      id: '1',
      date: 'Oct 31, 2023',
      name: 'Halloween',
      color: '#286EB4',
    },
    {
      id: '2',
      date: '24 Nov 2023',
      name: 'Black Friday',
      color: '#C0399F',
    },
    {
      id: '3',
      date: '25 Dec 2023',
      name: 'Christmas Day',
      color: '#6367DE',
    },
    {
      id: '4',
      date: '31 Dec 2023',
      name: 'New Year',
      color: '#7CA10C',
    },
  ];

  constructor(private router: Router) {}

  createCampaign() {
    this.router.navigate(['/store-generator/create-campaign']);
  }
}
