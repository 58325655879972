import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/core/local-storage.service';

@Component({
  selector: 'app-optimize-store-page',
  templateUrl: './optimize-store-page.component.html',
  styleUrls: ['./optimize-store-page.component.scss'],
})
export class OptimizeStorePageComponent implements OnInit {
  tabs = [
    { id: 'basic-optimization', text: 'Basic Optimization' },
    { id: 'campaign-optimazation', text: 'Campaign Optimazation' },
    { id: 'optimazation-history', text: 'Optimazation History' },
  ];
  stores = [{ id: 'fun-shop', name: 'Fun Shop' }];
  currentStore = 'fun-shop';
  needOptimization = false;
  checked1 = false;
  checked2 = false;

  constructor(private localStorageService: LocalStorageService) {}

  get isFooterShow() {
    return this.needOptimization;
  }

  ngOnInit(): void {
    const isStoreCreated = this.localStorageService.getItem('isStoreCreated');
    const needOptimization = this.localStorageService.getItem('needOptimization');
    if (Boolean(isStoreCreated) === true) {
      this.checked1 = true;
    }
    if (Boolean(needOptimization) === true) {
      this.checked2 = true;
      this.needOptimization = true;
    }
  }

  onSwitchChange1(val) {
    console.log(val);
    this.localStorageService.setItem('isStoreCreated', val);
  }
  onSwitchChange2(val) {
    console.log(val);
    this.localStorageService.setItem('needOptimization', val);
  }

  noop(val?: any) {}
}
