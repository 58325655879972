import { Component, Output, EventEmitter } from '@angular/core';
import { PopupTag, PopupTagId, HotZoneId, CONFIG } from '../../types';
import { PreviewControlService } from './preview-control.service';

@Component({
  selector: 'app-preview-control',
  templateUrl: './preview-control.component.html',
  styleUrls: ['./preview-control.component.scss'],
})
export class PreviewControlComponent {
  @Output() apply = new EventEmitter<{
    tagId: string;
    data: any;
  }>();

  isLoading = this.previewControlService.isLoading$;
  tags: PopupTag[] = [];
  title = '';
  currentZone: string = '';
  currentTag: string = '';
  navigatorZone = HotZoneId.Navigator;
  bannerZone = HotZoneId.Banner;
  recommendationZone = HotZoneId.Recommendation;
  productZone = HotZoneId.Product;
  emptyImg = `${window.__webpack_public_path__}assets/images/edit-empty.png`;

  constructor(private previewControlService: PreviewControlService) {}

  updateTitle(hotZoneId: string, tag?: PopupTag) {
    switch (hotZoneId) {
      case HotZoneId.Navigator:
        this.title = 'Navigator Text';
        break;
      case HotZoneId.Banner:
        if (tag && tag.id === PopupTagId.BannerImg) {
          this.title = 'Banner Image';
        } else if (tag && tag.id === PopupTagId.BannerText) {
          this.title = 'Banner Text';
        } else {
          this.title = '';
        }
        break;
      case HotZoneId.Recommendation:
        this.title = 'Recommendation Products';
        break;
      case HotZoneId.Product:
        this.title = 'Product Image';
        break;
      default:
        this.title = '';
        break;
    }
  }
  updateCurrentZone(id: string) {
    this.currentZone = id;
  }
  updateCurrentTag(id: string) {
    this.currentTag = id;
  }
  getTags(id: string) {
    const tags = CONFIG[id]?.tags || [];
    return tags;
  }
  updateTags(tag: PopupTag) {
    const { id } = tag;
    this.tags = this.tags.filter((tag) => tag.id !== id);
  }
  onUpdate(hotZoneId: string, tag: PopupTag) {
    this.onOpen(hotZoneId, tag);
    this.updateCurrentTag(tag.id);
    this.updateTags(tag);
  }
  onOpen(hotZoneId: string, tag: PopupTag) {
    if (this.currentZone !== hotZoneId) {
      this.updateCurrentZone(hotZoneId);
      this.tags = this.getTags(hotZoneId);
    }
    if (this.currentTag !== tag.id) {
      this.updateTitle(hotZoneId, tag);
    }
  }
  onClose() {
    this.tags = [];
    this.updateCurrentZone('');
    this.updateCurrentTag('');
    this.updateTitle('');
  }
  onApply(val) {
    console.log(val);
    this.apply.emit(val);
  }
}
