<fd-wizard [appendToWizard]="false" [displaySummaryStep]="true">
  <fd-wizard-navigation>
    <ul fd-wizard-progress-bar size="sm" role="list" aria-label="Wizard Steps">
      <li
        fd-wizard-step
        [status]="step1status"
        (statusChange)="statusChanged(1, $event)"
        label="General"
        ariaLabel="Step 1: General"
        (keydown)="handleFocus($event, 0)"
      >
        <fd-wizard-step-indicator>1</fd-wizard-step-indicator>
        <fd-wizard-content size="sm">
          <app-tc-step1 (setCompleted)="setCurrentStepCompleteStatus($event)"></app-tc-step1>
        </fd-wizard-content>
      </li>
      <li
        fd-wizard-step
        [status]="step2status"
        (statusChange)="statusChanged(2, $event)"
        label="Campaign Resources(optional)"
        ariaLabel="Step 2: Campaign Resources(optional)"
        (keydown)="handleFocus($event, 1)"
      >
        <fd-wizard-step-indicator>2</fd-wizard-step-indicator>
        <fd-wizard-content size="sm">
          <app-tc-step2 (setCompleted)="setCurrentStepCompleteStatus($event)"></app-tc-step2>
        </fd-wizard-content>
      </li>
      <li
        fd-wizard-step
        [status]="step3status"
        (statusChange)="statusChanged(3, $event)"
        label="Campaign Products"
        ariaLabel="Step 3: Campaign Products"
        (keydown)="handleFocus($event, 2)"
      >
        <fd-wizard-step-indicator>3</fd-wizard-step-indicator>
        <fd-wizard-content size="sm">
          <app-tc-step3></app-tc-step3>
        </fd-wizard-content>
      </li>
      <li
        fd-wizard-step
        [status]="step4status"
        (statusChange)="statusChanged(4, $event)"
        label="Preview"
        ariaLabel="Step 4: Preview"
        (keydown)="handleFocus($event, 3)"
      >
        <fd-wizard-step-indicator>4</fd-wizard-step-indicator>
        <fd-wizard-content size="sm"> </fd-wizard-content>
      </li>
    </ul>
  </fd-wizard-navigation>
</fd-wizard>
<div fd-bar barDesign="floating-footer">
  <div fd-bar-right>
    <fd-button-bar
      *ngIf="step2status === 'current'"
      label="Skip Step"
      (click)="goToStep(currentStep + 1)"
    ></fd-button-bar>
    <fd-button-bar
      fdType="emphasized"
      *ngIf="step4status !== 'current'"
      [label]="'Next Step'"
      [disabled]="!stepCompleted"
      (click)="goToStep(currentStep + 1)"
    ></fd-button-bar>
    <fd-button-bar
      fdType="emphasized"
      *ngIf="step4status === 'current'"
      label="Publish"
    ></fd-button-bar>
    <fd-button-bar label="Cancel"></fd-button-bar>
  </div>
</div>
