import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  // HostListener,
  ElementRef,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  Renderer2,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

import { GlobalLoadingService } from 'src/app/widgets/global-loading/global-loading.service';
import { DataCenterService } from 'src/app/core/data-center.service';
import { PreviewScreenService } from './preview-screen.service';
import { PreviewPopupComponent } from '../components/preview-popup/preview-popup.component';
import { throttle } from 'src/app/common/utils';
import { TargetNode, HotZone, PopupTag, CONFIG } from '../../types';

const DELAY = 1000;
const REFRESH_DELAY = 0;
const RESIZE_DELAY = 300;
const MOUSE_DELAY = 0;
const IFRAME_MOUSE_DELAY = 0;
const DELTA = 5;

@Component({
  selector: 'app-preview-screen',
  templateUrl: './preview-screen.component.html',
  styleUrls: ['./preview-screen.component.scss'],
})
export class PreviewScreenComponent implements OnInit, AfterViewInit {
  @ViewChild('container') container!: ElementRef;
  @ViewChild('previewIframe') Iframe!: ElementRef;
  @ViewChild('popupContainer', { read: ViewContainerRef }) popupContainer!: ViewContainerRef;
  @Output() popupClick = new EventEmitter<{
    tag: PopupTag;
    hotZone: HotZone;
  }>();
  @Output() popupClose = new EventEmitter<void>();

  // 全局变量 是否在编辑
  isEditing$ = this.dataCenterService.isEditing$;
  hotZones: HotZone[] = [];
  resizeSubscription!: Subscription;
  mousemoveSubscription!: Subscription;
  timeout!: NodeJS.Timeout | undefined;
  currentMouse = {
    x: 0,
    y: 0,
  };
  popupComponent!: any;
  currentHotZone!: HotZone;
  targetNodes!: TargetNode[];
  productTargetNodes!: TargetNode[];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private loadingService: GlobalLoadingService,
    private dataCenterService: DataCenterService,
    private previewScreenService: PreviewScreenService,
  ) {}

  get shopName() {
    return this.dataCenterService.shopNameSubject.value;
  }
  get isFunshop() {
    return this.shopName && this.shopName === 'funshop';
  }
  get isChrishop() {
    return this.shopName && this.shopName === 'chrishop';
  }

  get isEditing() {
    return this.dataCenterService.isEditingSubject.value === true;
  }

  // TODO:
  ngOnInit() {
    this.loadingService.show();
    this.isEditing$.subscribe((isEditing) => {
      if (isEditing === true) {
        setTimeout(() => {
          this.updateHotZone();
        }, 1000);
      }
    });
  }

  ngAfterViewInit() {
    const resizeObservable = fromEvent(window, 'resize');
    this.resizeSubscription = resizeObservable
      .pipe(debounceTime(RESIZE_DELAY))
      .subscribe((event) => {
        this.onResize(event);
      });
    const mousemoveObservable = fromEvent(this.container.nativeElement, 'mousemove');
    this.mousemoveSubscription = mousemoveObservable
      .pipe(throttleTime(MOUSE_DELAY))
      .subscribe((event) => {
        this.onMouseMove(event);
      });
  }

  onIframeLoad(event) {
    const { targetNodes, productTargetNodes } = this.dataCenterService.getTargetNodes();
    this.targetNodes = targetNodes;
    this.productTargetNodes = productTargetNodes;
    console.log('**** onIframeLoad ****', event);
    const IfDocument = this.getIframeDoc();
    // 禁止iframe滚动
    this.previewScreenService.hackIframeStyle(IfDocument);
    setTimeout(() => {
      // 每隔一秒更新热区 一共5次
      this.previewScreenService.runIntervalTask(this.updateHotZone.bind(this));
      // 监听iframe banner是否加载
      this.previewScreenService.runIntervalTaskOnce(this.listenToIframeBannerLoad.bind(this));
      this.previewScreenService.runIntervalTaskOnce(this.listenToIframeBtnClick.bind(this));
      this.listenToIframeMousemove(IfDocument);
    }, 1000);
    setTimeout(async () => {
      try {
        // 根据iframe里的高度更新外面高度
        await this.previewScreenService.runUpdateIframeSize(IfDocument, this.Iframe);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingService.hide();
      }
    }, 1000);
    setTimeout(() => {
      const Iframe = this.Iframe.nativeElement;
      const IframeWindow = Iframe.contentWindow;
      if (IframeWindow?.proxyWindow) {
        Reflect.set(IframeWindow.proxyWindow, 'funshopReload', false);
      }
    }, 5000);
    // setTimeout(() => {
    //   this.Iframe.nativeElement.contentWindow.onresize = this.onIframeResize;
    // }, 1000);
  }

  // onIframeResize(event) {
  //   console.log('**** onIframeResize ****', event);
  //   const height = event.target.document.body.clientHeight;
  //   if (height) {
  //     this.previewScreenService.updateIframeSize(this.Iframe, height);
  //   }
  // }

  // 窗口拖动 更新热区
  // @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.isEditing) {
      return;
    }
    const handler = () => {
      const IfDocument = this.getIframeDoc();
      const height = this.previewScreenService.getIframeSize(IfDocument);
      this.previewScreenService.updateIframeSize(this.Iframe, height);
      this.updateHotZone();
      this.updateComponentPosition();
    };
    setTimeout(() => {
      handler();
    }, 500);
    setTimeout(() => {
      handler();
    }, 1000);
  }

  // 鼠标移入热区
  onMouseEnter(event, hotZone: HotZone) {
    event.preventDefault();
    const { id } = hotZone;
    console.log('onMouseEnter', id);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      const tags = CONFIG[id]?.tags;
      this.loadComponent(this.popupContainer, tags, hotZone);
    }, DELAY);
    this.hideAllHotZone();
    this.previewScreenService.showHotZone(hotZone);
  }

  // 鼠标移出热区
  onMouseLeave(event, hotZone: HotZone) {
    event.preventDefault();
    const { id } = hotZone;
    console.log('onMouseLeave', id);
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    this.previewScreenService.hideHotZone(hotZone);
  }

  onMouseMove(event) {
    event.preventDefault();
    // console.log('onMouseMove', event);
    const currentElement = event.target as HTMLElement;
    const currentId = currentElement?.id;
    console.log(`当前元素的ID是: ${currentId}`);
    this.currentMouse.x = event.clientX;
    this.currentMouse.y = event.clientY;
  }

  hideAllHotZone() {
    for (const hotZone of this.hotZones) {
      this.previewScreenService.hideHotZone(hotZone);
    }
  }

  // 更新热区的位置 保持和iframe里的元素位置一致
  updateHotZone() {
    const IfDocument = this.getIframeDoc();
    if (IfDocument) {
      const result = this.previewScreenService.runUpdateHotZone(IfDocument, [
        ...this.targetNodes,
        ...this.productTargetNodes,
      ]);
      if (result?.length) {
        this.hotZones = result;
      }
    }
  }

  calcRelativePosition() {
    const containerRect = this.container.nativeElement.getBoundingClientRect();
    const containerScrollTop = this.container.nativeElement.scrollTop;
    const top = this.currentMouse.y + containerScrollTop - containerRect.top;
    const left = this.currentMouse.x - containerRect.left;
    return {
      top,
      left,
    };
  }

  // 动态加载popup
  loadComponent(container, tags: PopupTag[], hotZone: HotZone) {
    container.clear();
    // container.innerHTML = '';
    const factory = this.componentFactoryResolver.resolveComponentFactory(PreviewPopupComponent);
    // const component = factory.create(container.parentInjector);
    const component = container.createComponent(factory);
    this.popupComponent = component;

    const { top, left } = this.calcRelativePosition();
    this.renderer.setAttribute(component.location.nativeElement, 'id', 'preview-popup');
    this.renderer.setStyle(component.location.nativeElement, 'position', 'absolute');
    this.renderer.setStyle(component.location.nativeElement, 'top', `${top}px`);
    this.renderer.setStyle(component.location.nativeElement, 'left', `${left + 15}px`);
    component.location.nativeElement.classList.add('dynamic-component');
    component.instance.tags = tags;
    component.instance.select.subscribe((tag: PopupTag) => {
      this.popupClick.emit({ tag, hotZone });
    });
    component.instance.close.subscribe(() => {
      this.removeComponent(this.container);
      this.popupClose.emit();
    });
    // container.insert(component.hostView, 0);
    // this.viewContainerRef.insert(component.hostView);
  }

  removeComponent(container) {
    const dynamicComponents = container.nativeElement.querySelectorAll('.dynamic-component');
    dynamicComponents.forEach((element) => {
      element.remove();
    });
  }

  updateComponentPosition() {
    if (this.popupComponent?.instance) {
      this.popupComponent?.instance.checkPosition();
    }
  }

  getIframeDoc() {
    const Iframe = this.Iframe.nativeElement;
    return Iframe.contentDocument || Iframe?.contentWindow?.document;
  }

  listenToIframeMousemove(ifDoc: Document) {
    const onMove = throttle(this.onIframeMousemove, IFRAME_MOUSE_DELAY);
    ifDoc.addEventListener('mousemove', onMove.bind(this));
  }

  onIframeMousemove(event) {
    if (!this.isEditing) {
      return;
    }
    const { clientX, clientY } = event;
    for (const hotZone of this.hotZones) {
      const { area } = hotZone;
      if (
        clientX + DELTA >= area.x1 &&
        clientX - DELTA <= area.x2 &&
        clientY + DELTA >= area.y1 &&
        clientY - DELTA <= area.y2
      ) {
        this.previewScreenService.showHotZone(hotZone);
      } else {
        this.previewScreenService.hideHotZone(hotZone);
      }
    }
  }

  listenToIframeBannerLoad() {
    let done = false;
    const IfDocument = this.getIframeDoc();

    const banner = this.dataCenterService.getBanner(IfDocument);
    if (banner) {
      const img = banner.querySelector('img');
      if (img && !img.complete) {
        img.addEventListener('load', this.onIframeBannerLoad.bind(this));
        done = true;
      }
    }
    return done;
  }

  onIframeBannerLoad() {
    console.log('**** onIframeBannerLoad ****');
    this.updateHotZone();
  }

  listenToIframeBtnClick() {
    let done = false;
    const IfDocument = this.getIframeDoc();
    const swipper = this.dataCenterService.getProductSwiper(IfDocument);
    if (swipper) {
      const btns = this.dataCenterService.getNavBtnsFromSwiper(swipper);
      for (const btn of btns) {
        btn.addEventListener('click', this.onIframeBtnClick.bind(this));
      }
      done = true;
    }
    return done;
  }

  onIframeBtnClick(event) {
    setTimeout(() => {
      const IfDocument = this.getIframeDoc();
      const swipperItems = this.dataCenterService.getProductSwiperItems(IfDocument);
      const { productTargetNodes } = this.dataCenterService.getTargetNodes();
      this.productTargetNodes = productTargetNodes.slice(0, swipperItems.length);
      this.updateHotZone();
    }, 0);
    this.hideAllHotZone();
  }

  refreshIframe() {
    const iframe = this.Iframe.nativeElement as HTMLIFrameElement;
    iframe.src = iframe.src;
    setTimeout(() => {
      this.container.nativeElement.scrollTop = 0;
    }, REFRESH_DELAY);
  }
}
