import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { genCustomId } from 'src/app/common/utils';
import { HotZoneId } from '../store-generator/types';

const FunShop = {
  BannerSelector: 'cx-banner',
  BannerIndex: 1,
  NavigatorSelector: '.NavigationBar',
  NavigatorIndex: 0,
  RecommendationSelector: 'cx-carousel',
  RecommendationIndex: 0,
  ProductSwiperSelector: 'cx-product-carousel',
  ProductSwiperIndex: 1,
  ProductItemSelector: '.item.active',
};
const ChriShop = {
  BannerSelector: '.Banner',
  BannerIndex: 0,
  NavigatorSelector: '.NavigationBar',
  NavigatorIndex: 0,
  RecommendationSelector: '.CarouselPanel',
  RecommendationIndex: 0,
  ProductSwiperSelector: '.CarouselPanel',
  ProductSwiperIndex: 1,
  ProductItemSelector: '.CarouselItem',
};

@Injectable({
  providedIn: 'root',
})
export class DataCenterService {
  isEditingSubject = new BehaviorSubject<boolean>(false);
  isEditing$ = this.isEditingSubject.asObservable();

  storeUrlSubject = new BehaviorSubject<string>('');
  storeUrl$ = this.storeUrlSubject.asObservable();

  shopNameSubject = new BehaviorSubject<string>('');
  shopName$ = this.shopNameSubject.asObservable();

  constructor(private http: HttpClient) {}

  get isEditing(): boolean {
    return this.isEditingSubject.value;
  }

  get storeUrl(): string {
    return this.storeUrlSubject.value;
  }

  get shopName(): string {
    return this.shopNameSubject.value;
  }

  private handleError(res: HttpErrorResponse | any) {
    console.error(res.error || res.body.error);
    return throwError(() => new Error(res.error || 'Server error'));
  }

  updateEditing(val) {
    this.isEditingSubject.next(val);
  }

  toggleEditing() {
    this.isEditingSubject.next(!this.isEditingSubject.value);
  }

  updateStoreUrl(val: string) {
    this.storeUrlSubject.next(val);
  }

  updateShopName(val: string) {
    this.shopNameSubject.next(val);
  }

  getAllData(): Observable<any> {
    return this.http.get<any>('/api/flow-riser-backend/edit/configs').pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError),
    );
  }

  getBannerText(): Observable<any> {
    return this.getAllData().pipe(
      map((data) => ({
        title: data['banner-title-text'] || '',
        subtitle: data['banner-subtitle-text'] || '',
        button: data['banner-button-text'] || '',
      })),
    );
  }

  getShopInfo() {
    if (this.shopName === 'funshop') {
      return FunShop;
    }
    if (this.shopName === 'chrishop') {
      return ChriShop;
    }
    return null;
  }

  getTargetNodes() {
    const Shop = this.getShopInfo();
    if (Shop) {
      const BannerNode = {
        selector: Shop.BannerSelector,
        index: Shop.BannerIndex,
        label: 'Banner',
        id: HotZoneId.Banner,
      };
      const NavigatorNode = {
        selector: Shop.NavigatorSelector,
        index: Shop.NavigatorIndex,
        label: 'Navigator',
        id: HotZoneId.Navigator,
      };
      const RecommendationNode = {
        selector: Shop.RecommendationSelector,
        index: Shop.RecommendationIndex,
        label: 'Recommendation',
        id: HotZoneId.Recommendation,
      };
      const targetNodes = [BannerNode, NavigatorNode, RecommendationNode];
      const productTargetNodes = [
        {
          selector: [
            Shop.ProductSwiperSelector,
            Shop.ProductSwiperIndex,
            Shop.ProductItemSelector,
            0,
          ],
          index: 0,
          label: 'Product',
          id: HotZoneId.Product,
          genCustomId,
        },
        {
          selector: [
            Shop.ProductSwiperSelector,
            Shop.ProductSwiperIndex,
            Shop.ProductItemSelector,
            1,
          ],
          index: 0,
          label: 'Product',
          id: HotZoneId.Product,
          genCustomId,
        },
        {
          selector: [
            Shop.ProductSwiperSelector,
            Shop.ProductSwiperIndex,
            Shop.ProductItemSelector,
            2,
          ],
          index: 0,
          label: 'Product',
          id: HotZoneId.Product,
          genCustomId,
        },
        {
          selector: [
            Shop.ProductSwiperSelector,
            Shop.ProductSwiperIndex,
            Shop.ProductItemSelector,
            3,
          ],
          index: 0,
          label: 'Product',
          id: HotZoneId.Product,
          genCustomId,
        },
      ];
      return { targetNodes, productTargetNodes };
    }
    return { targetNodes: [], productTargetNodes: [] };
  }

  getBanner(doc: Document) {
    const ShopInfo = this.getShopInfo();
    if (!ShopInfo) {
      return;
    }
    return doc.querySelectorAll(ShopInfo.BannerSelector)[ShopInfo.BannerIndex];
  }

  getNavigaitonBar(doc: Document) {
    const ShopInfo = this.getShopInfo();
    if (!ShopInfo) {
      return;
    }
    return doc.querySelectorAll(ShopInfo.NavigatorSelector)[ShopInfo.NavigatorIndex];
  }

  getProductSwiper(doc: Document) {
    const ShopInfo = this.getShopInfo();
    if (!ShopInfo) {
      return;
    }
    return doc.querySelectorAll(ShopInfo.ProductSwiperSelector)[ShopInfo.ProductSwiperIndex];
  }

  getProductSwiperItems(doc: Document) {
    const ShopInfo = this.getShopInfo();
    if (!ShopInfo) {
      return [];
    }
    return doc
      .querySelectorAll(ShopInfo.ProductSwiperSelector)
      [ShopInfo.ProductSwiperIndex].querySelectorAll(ShopInfo.ProductItemSelector);
  }

  getItemsFromSwiper(swipper: Element) {
    const ShopInfo = this.getShopInfo();
    if (!ShopInfo) {
      return [];
    }
    return swipper.querySelectorAll(ShopInfo.ProductItemSelector);
  }

  getNavBtnsFromSwiper(swipper: Element) {
    const btns: Element[] = [];
    if (this.shopName === 'funshop') {
      const previousBtn = swipper.querySelector('button.previous');
      const nextBtn = swipper.querySelector('button.next');
      const indicatorBtns = swipper.querySelectorAll('.indicators > button');
      if (previousBtn) {
        btns.push(previousBtn);
      }
      if (nextBtn) {
        btns.push(nextBtn);
      }
      if (indicatorBtns.length) {
        Array.prototype.push.apply(btns, Array.from(indicatorBtns));
      }
    } else if (this.shopName === 'chrishop') {
      const navBtns = swipper.querySelectorAll('.CarouselAction');
      const indicatorBtns = swipper.querySelectorAll('.Pagination > .PaginationItem');
      if (navBtns.length) {
        Array.prototype.push.apply(btns, Array.from(navBtns));
      }
      if (indicatorBtns.length) {
        Array.prototype.push.apply(btns, Array.from(indicatorBtns));
      }
    }
    return btns;
  }

  updateBannerImg(doc: Document, data: any) {
    const banner = this.getBanner(doc);
    if (banner) {
      const target = banner.querySelector('img');
      if (target) {
        target.src = data.url;
        target.srcset = data.url;
      }
    }
  }

  updateBannerText(doc: Document, data: any) {
    const banner = this.getBanner(doc);
    if (banner) {
      if (this.shopName === 'funshop') {
        const titleRule = `main cx-banner::before { content: "${data.title}" !important; }`;
        banner.insertAdjacentHTML('beforebegin', '<style>' + titleRule + '</style>');
        const subtitleRule = `main cx-banner .is-initialized::after { content: "${data.subtitle}" !important; }`;
        banner.insertAdjacentHTML('beforebegin', '<style>' + subtitleRule + '</style>');
        const buttonRule = `main cx-banner .is-initialized::before { content: "${data.button_text}" !important; }`;
        banner.insertAdjacentHTML('beforebegin', '<style>' + buttonRule + '</style>');
      } else if (this.shopName === 'chrishop') {
      }
    }
  }

  updateNavFontSize(doc: Document, data: any) {
    const nav = this.getNavigaitonBar(doc);
    if (nav) {
      if (this.shopName === 'funshop') {
        const target = nav.querySelector('ul');
        if (target) {
          target.style['font-size'] = data.size;
        }
      } else if (this.shopName === 'chrishop') {
        const targets = nav.querySelectorAll('a');
        if (targets.length) {
          targets.forEach((target) => {
            target.style['font-size'] = data.size;
          });
        }
      }
    }
  }

  updateRecommendations(Iframe: HTMLIFrameElement) {
    const IframeWindow = Iframe.contentWindow;
    if (this.shopName === 'funshop') {
      if (IframeWindow && 'proxyWindow' in IframeWindow) {
        const proxyWindow = IframeWindow.proxyWindow as Record<string, any>;
        Reflect.set(proxyWindow, 'funshopReload', true);
        setTimeout(() => {
          Reflect.set(proxyWindow, 'funshopReload', false);
        }, 3000);
      }
    } else if (this.shopName === 'chrishop') {
      if (
        IframeWindow &&
        'fetchData' in IframeWindow &&
        typeof IframeWindow.fetchData === 'function'
      ) {
        IframeWindow.fetchData();
      }
    }
  }

  updateProductImg(doc: Document, data: any) {
    const swipperItems = this.getProductSwiperItems(doc);
    if (swipperItems.length) {
      const originalProdId = data.origin_id;
      const swipperItem = Array.from(swipperItems).find((item) => {
        const img = item.querySelector('img');
        if (
          img &&
          (img['data-id']?.indexOf(originalProdId) > -1 || img.src?.indexOf(originalProdId) > -1)
        ) {
          return true;
        }
        return false;
      });
      if (swipperItem) {
        const target = swipperItem.querySelector('img');
        if (target) {
          target.src = data.url;
          target.srcset = data.url;
        }
      } else {
        console.error(`Cannot find product img for id ${originalProdId}`);
      }
    }
  }
}
