import { Component, QueryList, ViewChildren } from '@angular/core';
import { WizardService, WizardStepComponent, WizardStepStatus } from '@fundamental-ngx/core/wizard';

@Component({
  selector: 'app-social-page',
  templateUrl: './social-page.component.html',
  styleUrls: ['./social-page.component.scss'],
})
export class SocialPageComponent {
  constructor(private _wizardService: WizardService) {}

  step1status: WizardStepStatus = 'current';
  step2status: WizardStepStatus = 'upcoming';
  step3status: WizardStepStatus = 'upcoming';
  step4status: WizardStepStatus = 'upcoming';
  summaryStatus: WizardStepStatus = 'upcoming';
  stepCompleted: boolean = false;

  currentStep = 1;

  /** @hidden */
  @ViewChildren(WizardStepComponent)
  wizardStepComponents!: QueryList<WizardStepComponent>;

  setCurrentStepCompleteStatus(isCompleted: boolean): void {
    this.stepCompleted = isCompleted;
  }

  statusChanged(stepNumber: number, event: WizardStepStatus): void {
    if (event === 'current') {
      this.goToStep(stepNumber);
    }
  }

  goToStep(step: number): void {
    switch (step) {
      case 1: {
        this.step1status = 'current';
        this.step2status = 'upcoming';
        this.step3status = 'upcoming';
        this.step4status = 'upcoming';
        this.currentStep = 1;
        break;
      }
      case 2: {
        this.step1status = 'completed';
        this.step2status = 'current';
        this.step3status = 'upcoming';
        this.step4status = 'upcoming';
        this.currentStep = 2;
        break;
      }
      case 3: {
        this.step1status = 'completed';
        this.step2status = 'completed';
        this.step3status = 'current';
        this.step4status = 'upcoming';
        this.currentStep = 3;
        break;
      }
      case 4: {
        this.step1status = 'completed';
        this.step2status = 'completed';
        this.step3status = 'completed';
        this.step4status = 'current';
        this.currentStep = 4;
        break;
      }
    }
  }

  // Handle focus on key press first example
  /** @hidden */
  handleFocus(event: KeyboardEvent, index: number): void {
    this._wizardService.progressBarKeyHandler(event, this.wizardStepComponents, index);
  }
}
