<div class="flex flex-wrap gap-4 mt-4">
  <div
    class="product-img-wrapper"
    *ngFor="let img of productImgs"
    [ngClass]="[selected === img ? 'is-selected' : '']"
    (click)="onClick(img)"
  >
    <img [src]="img.url" alt="" />
  </div>
</div>
<p class="mt-6">Want something specific for the image?</p>
<div class="mt-2.5 flex flex-wrap gap-2">
  <ng-container *ngFor="let tag of tags">
    <button
      class="img-tag"
      [ngClass]="[selectedTags.includes(tag.value) ? 'is-selected' : '']"
      (click)="onTagClick(tag.value)"
    >
      {{ tag.text }}
    </button>
  </ng-container>
  <button class="img-tag min-w-[40px]">...</button>
</div>
<div class="mt-2.5">
  <button
    class="fd-button-override"
    fd-button
    fdType="transparent"
    [disabled]="selectedTags.length === 0"
    (click)="onGenerate()"
  >
    Generate
  </button>
</div>
<p class="mt-6">Want to use your own background image?</p>
<div class="mt-2.5">
  <fd-file-uploader
    buttonLabel="Upload"
    accept=".png,.jpg,.jpeg"
    [required]="true"
    [(ngModel)]="files"
    [multiple]="false"
    (selectedFilesChanged)="onFileChanged($event)"
  ></fd-file-uploader>
</div>
