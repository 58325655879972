export const getCookieValue = (cookieName: string) => {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === cookieName) {
      return value;
    }
  }
  return null;
};

export const wait = (timeout = 2000) =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      resolve(true);
    }, timeout),
  );

export const throttle = (func, wait) => {
  let timer;
  return function (...args) {
    // @ts-ignore
    const context = this;
    if (!timer) {
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, wait);
    }
  };
};

export const getRandomItemFromArray = (arr: any[]) => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};

export const getIdFromSrc = (src: string) => {
  src = src.slice(0, src.indexOf('.png'));
  const id = src.slice(src.lastIndexOf('/') + 1);
  return id;
};

export const genCustomId = (elm) => {
  let id;
  if (elm) {
    const img = elm.querySelector('img');
    if (img['data-id']) {
      id = img['data-id'];
    } else {
      if (img?.src) {
        id = getIdFromSrc(img.src);
        img['data-id'] = id;
      }
    }
  }
  return id;
};
