<div cdkScrollable #overlay>
  <ng-container>
    <!-- Flexible Column Layout -->
    <fd-flexible-column-layout [(layout)]="localLayout">
      <ng-template #startColumn>
        <div class="flex flex-col space-y-2">
          <fd-toolbar>
            <h3 fd-title>2. Campaign Resources</h3>
            <fd-toolbar-spacer></fd-toolbar-spacer>
            <button fd-button fdType="transparent" label="Preview"></button>
          </fd-toolbar>
          <div>
            <fd-segmented-button [(ngModel)]="selectedCampaignResource">
              <button class="w-40" fd-button label="Fashion Icon" value="fashionIcon"></button>
              <button class="w-40" fd-button label="Manual Upload" value="manualUpload"></button>
            </fd-segmented-button>
          </div>
          <div *ngIf="selectedCampaignResource === 'fashionIcon'" class="searchDiv">
            <app-fashion-icon></app-fashion-icon>
          </div>
        </div>
        <div
          class="w-11/12 flex flex-col space-y-2"
          *ngIf="selectedCampaignResource === 'manualUpload'"
        >
          <app-manual-upload></app-manual-upload>
        </div>
      </ng-template>
      <ng-template #midColumn>
        <app-preview-card
          (changeLayout)="changeLayout('OneColumnStartFullScreen')"
        ></app-preview-card>
      </ng-template>
    </fd-flexible-column-layout>
  </ng-container>
</div>
