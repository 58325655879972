import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CreateStorePageService {
  constructor(private http: HttpClient) {}

  createStores(data: { url: string }): Observable<any[]> {
    return this.http.post<any>('/api/flow-riser-backend/generate/shop', data).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError),
    );
  }

  syncFigma(url: string): Observable<any[]> {
    const params = new HttpParams().set('url', url);
    return this.http.get<any>('/api/flow-riser-backend/figma/sync', { params }).pipe(
      map((data) => {
        return data;
      }),
      catchError(this.handleError),
    );
  }

  private handleError(res: HttpErrorResponse | any) {
    console.error(res.error || res.body.error);
    return throwError(() => new Error(res.error || 'Server error'));
  }
}
