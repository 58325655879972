<ng-template [ngIf]="isImg">
  <div class="mt-4">
    <button class="fd-button-override" fd-button fdType="transparent"
    (click)="onGenerateBanner()">Try something new</button>
  </div>
  <p class="mt-6">Want something specific for the image?</p>
  <div class="mt-2.5 flex flex-wrap gap-2">
    <ng-container *ngFor="let tag of tags">
      <button
        class="img-tag"
        [ngClass]="[selectedTags.includes(tag) ? 'is-selected' : '']"
        (click)="onTagClick(tag)"
      >
        {{ tag }}
      </button>
    </ng-container>
    <button class="img-tag min-w-[40px]">...</button>
  </div>
  <div class="mt-2.5">
    <button
      class="fd-button-override"
      fd-button
      fdType="transparent"
      [disabled]="selectedTags.length === 0"
      (click)="onGenerateBanner()"
    >
      Generate
    </button>
  </div>
</ng-template>
<ng-template [ngIf]="isText">
  <div class="mt-4">
    <button class="fd-button-override" fd-button fdType="transparent" (click)="onGenerateText()">
      Try something new
    </button>
  </div>
  <fd-form-group>
    <ng-container *ngFor="let each of formElements; let i = index">
      <div fd-form-item class="!mt-6">
        <label class="form-item-label" fd-form-label [required]="true" [for]="'input-' + i"
          >{{ each.label }}:</label
        >
        <div class="flex items-center">
          <button
            class="fd-button-override"
            fd-button
            fdType="transparent"
            glyph="synchronize"
            (click)="onRefreshText(each.field)"
          ></button>
          <input
            fd-form-control
            class="!ml-2"
            [id]="'input-' + i"
            type="text"
            [placeholder]="each.placeholder"
            [value]="form[each.field]"
            (input)="onInputChange($event, each.field)"
          />
        </div>
      </div>
    </ng-container>
    <div class="mt-6">
      <button
        class="fd-button-override"
        fd-button
        fdType="transparent"
        [disabled]="isApplyDisabled"
        (click)="onApply()"
      >
        Apply
      </button>
    </div>
  </fd-form-group>
</ng-template>
