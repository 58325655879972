import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { FdDate } from '@fundamental-ngx/core/datetime';
import { TableDataSource, TableDataProvider, TableState } from '@fundamental-ngx/platform/table';
@Component({
  selector: 'app-published-compaigns',
  templateUrl: './published-compaigns.component.html',
  styleUrls: ['./published-compaigns.component.scss'],
})
export class PublishedCompaignsComponent {
  stores = [
    { id: 'fun-shop', name: 'Fun Shop' },
    { id: 'empty-shop', name: 'Empty Shop' },
  ];

  currentStore = 'fun-shop';
  source: TableDataSource<CampaignItem>;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.source = new TableDataSource(new TableDataProviderExample());
  }

  onCreate() {
    this.router.navigate(['/create-new-campaign']);
  }
}

export interface CampaignItem {
  name: string;
  channel: string;
  purpose: string;
  publishedDate: FdDate;
  navigatable?: boolean;
}

/**
 * Table Data Provider Example
 *
 */
export class TableDataProviderExample extends TableDataProvider<CampaignItem> {
  override items: CampaignItem[] = [...ITEMS];
  override totalItems = ITEMS.length;

  override fetch(tableState?: TableState): Observable<CampaignItem[]> {
    this.items = [...ITEMS];

    // apply searching
    if (tableState?.searchInput) {
      this.items = this.search(this.items, tableState);
    }

    this.totalItems = this.items.length;

    return of(this.items);
  }
}

// Example items
const ITEMS: CampaignItem[] = [
  {
    name: 'First, second- Last chance!',
    channel: 'Tiktok',
    purpose: 'General Promotion',
    publishedDate: new FdDate(2023, 1, 8),
    navigatable: true,
  },
  {
    name: 'Gift for her',
    channel: 'Little Red Book',
    purpose: 'Valentine’s Day',
    publishedDate: new FdDate(2023, 5, 20),
    navigatable: true,
  },
  {
    name: 'Shop our Halloween bestsellers',
    channel: 'Instagram',
    purpose: 'Halloween',
    publishedDate: new FdDate(2022, 10, 30),
    navigatable: true,
  },
];
